import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UtilsService } from '../utils/utils.service';
import { catchError, map, Observable, of } from 'rxjs';
import { LoginInfo } from '../../models/login/login-info.model';
import { UserService } from '../user/user.service';
import { LoginService } from '../login/login.service';
import { PageLayoutService } from '../page-layout/page-layout.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  private router = inject(Router);
  private utilService = inject(UtilsService);
  private userService = inject(UserService);
  private pageLayoutService = inject(PageLayoutService);


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const loginInfo: LoginInfo = this.utilService.getToken() as LoginInfo;

    if (!loginInfo || !loginInfo.accessToken || !loginInfo.idToken) {
      window.location.href = `/unauthorized?redirect=${encodeURIComponent(state.url)}`;
      return false;
    }
    // Check session validity with API call
    return this.userService.checkUserSession().pipe(
      map((isValidSession: string) => {
        // Convert string to boolean
        const isValidSessionBool = (isValidSession === 'true');
        
        if (isValidSessionBool) {
          // If session is valid, allow route activation
          return true;
        } else {
          // If session is invalid, redirect to unauthorized page
          this.pageLayoutService.setPageLayout(3);
          return false;
        }
      }),
      catchError((error: any) => {
        // Handle error and redirect to unauthorized page
        this.pageLayoutService.setPageLayout(3);
        return of(false); // Return an Observable with `false` value
      })
    );
  }
}
