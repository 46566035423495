import { Component, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ProfileService } from 'src/app/core/services/profile/profile.service';
import { LoginService } from 'src/app/core/services/login/login.service';
import { RoleList } from 'src/app/core/constant/role.constant';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profilepage',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './profilepage.component.html',
  styleUrls: ['./profilepage.component.scss']
})
export class ProfilepageComponent implements OnInit {
  constructor(private router: Router, private _location: Location, private formBuilder: FormBuilder, private profileService: ProfileService, private loginService: LoginService, private toastr: ToastrService) {
  }
  file: File | undefined;
  profileImageUrl: string = "assets/images/person-circle.svg";
  baseImageUrl: string = "https://localhost:44353/api/User/GetUserImage/"


  profileForm: FormGroup = new FormGroup({

    firstName: new FormControl({ value: '', disabled: true }, [Validators.required]),
    lastName: new FormControl({ value: '', disabled: true }, [Validators.required]),
    email: new FormControl({ value: '', disabled: true }, [Validators.required]),
    jobTitle: new FormControl({ value: '', disabled: true }, [Validators.required]),
    institution: new FormControl({ value: '', disabled: true }, [Validators.required]),
    affiliation: new FormControl({ value: '', disabled: true }, [Validators.required]),
    country: new FormControl({ value: '', disabled: true }, [Validators.required]),
    role: new FormControl({ value: '', disabled: true }, [Validators.required])
  });

  goBack() {
    this._location.back();
  }


  ngOnInit(): void {
    this.getUsers();
  }

  getUsers() {
    this.profileService.getProfiledetailbyid()
      .subscribe({
        next: (res: any) => {
          const user = res;
          if (user.profilePicture !== null) {
            this.profileImageUrl = this.getProfilePicture(user.profilePicture);
          }

          this.profileForm.patchValue({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            jobTitle: user.jobTitle,
            institution: user.institution,
            affiliation: user.affiliation,
            country: user.country?.name,
            role: this.getRole(user.roleId)
          })
        },
        error: (err: any) => {
        }
      });
  }

  onFileChange(event: any) {
    const files = event.target.files as FileList;

    if (files.length > 0) {
      this.profileImageUrl = URL.createObjectURL(files[0]);
      this.file = files[0];
      this.resetInput();
    }
  }

  resetInput() {
    const input = document.getElementById('avatar-input-file') as HTMLInputElement;
    if (input) {
      input.value = "";
    }
  }

  getProfilePicture(imgName: string): string {
    return this.baseImageUrl + imgName;
  }

  getRole(roleId: number): string {
    const role = RoleList.find(x => x.roleId == roleId);
    if (role) return role.role;
    return "";
  }
}