import { Inject, Injectable, inject } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest, BrowserAuthError } from '@azure/msal-browser';
import { BehaviorSubject, Observable, Subject, catchError, filter, of, takeUntil } from 'rxjs';
import { LoginInfo } from '../../models/login/login-info.model';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggedInUser } from '../../models/user/loggedInUser.model';
import { UserService } from '../user/user.service';
import { RoleList } from '../../constant/role.constant';
import { Role } from '../../models/role/role.model';
import { PageLayoutService } from '../page-layout/page-layout.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private authService = inject(MsalService);
  private msalBroadcastService = inject(MsalBroadcastService);
  private router = inject(Router);
  private userService = inject(UserService);
  private readonly _destroying$ = new Subject<void>();
  private checkUserLoggedIn = new Subject<void>();
  loggedInUserInfo: LoggedInUser = JSON.parse(localStorage.getItem('logged_in_user') || '{}');
  isLoggedIN = new BehaviorSubject<LoggedInUser>(this.loggedInUserInfo);

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, private pageLayoutService: PageLayoutService) {}

  getLoginInfo(): Observable<LoggedInUser> {
    return this.isLoggedIN.asObservable();
  }

  setLoginInfo(param: any) {
    this.isLoggedIN.next(param);
  }

  updateUserStatus() {
    this.checkUserLoggedIn.next();
  }

  getUserStatus() {
    return this.checkUserLoggedIn.asObservable();
  }

  checkSessionExpire(): boolean {
    const expireOn: string = localStorage.getItem('expireOn') || new Date().toString();    
    var currDate : Date = new Date();
    //if token expire then clear session from local storage.
    if (currDate >= new Date(expireOn)) {
      localStorage.clear();
      this.setLoginInfo([]);
      return true;
    }
    return false;
  }

  preRequire(): void {    
    this.checkSessionExpire();        
    this.authService.handleRedirectObservable().subscribe();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });
  }

  setLoginDisplay(): boolean {   
    return !this.loggedInUserInfo || Object.keys(this.loggedInUserInfo).length === 0 ? false : true;
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  login(redirectUrl?: string): Observable<void> {
    return new Observable<void>((observer) => {
      if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
        if (this.msalGuardConfig.authRequest) {
          this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
            .pipe(
              catchError((error) => {
                if (error instanceof BrowserAuthError && error.errorCode === 'user_cancelled') {
                  observer.error(error);
                  return of(undefined);
                }
                throw error;
              })
            )
            .subscribe((response: AuthenticationResult | undefined) => {
              if (response) {
                this.authService.instance.setActiveAccount(response.account);
                this.saveLoggedInUserInfo(response, redirectUrl).then(() => {
                  observer.next();
                  observer.complete();
                });
              } else {
                observer.complete();
              }
            });
        } else {
          this.authService.loginPopup()
            .pipe(
              catchError((error) => {
                if (error instanceof BrowserAuthError && error.errorCode === 'user_cancelled') {
                  observer.error(error);
                  return of(undefined);
                }
                throw error;
              })
            )
            .subscribe((response: AuthenticationResult | undefined) => {
              if (response) {
                this.authService.instance.setActiveAccount(response.account);
                this.saveLoggedInUserInfo(response, redirectUrl).then(() => {
                  observer.next();
                  observer.complete();
                });
              } else {
                observer.complete();
              }
            });
        }
      } else {
        if (this.msalGuardConfig.authRequest) {
          this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
        } else {
          this.authService.loginRedirect();
        }
        observer.next();
        observer.complete();
      }
    });
  }

  async saveLoggedInUserInfo(response: AuthenticationResult, redirectUrl?: string): Promise<void> {
    const loginInfo: LoginInfo = {
      accessToken: response.accessToken,
      idToken: response.idToken
    };

    localStorage.setItem('login-info', JSON.stringify(loginInfo));
    localStorage.setItem('expireOn', response.expiresOn ? response.expiresOn.toString() : new Date().toString());

    try {
      const loggedInUser: LoggedInUser = await this.userService.getLoggedInUserDetails();
      const role: Role = RoleList.find(
        (r) => r.roleId === loggedInUser.roleId
      ) as Role;
      loggedInUser.roleDescription = role.description;
      loggedInUser.userRole = role.role;
      if (!loggedInUser.role) {
        loggedInUser.role = role;
      }

      localStorage.setItem('logged_in_user', JSON.stringify(loggedInUser));
      this.setLoginInfo(loggedInUser);
      this.updateUserStatus();
      
      // Set Page Layout to Not Normal.
      this.pageLayoutService.setPageLayout(1);

      if (redirectUrl) this.router.navigate([redirectUrl]);
      else this.router.navigate(['dashboard']);
    } catch (err: any) {
      this.logout();
      this.router.navigate(['/']);
    }
  }

  logout() {
    const activeAccount = this.authService.instance.getActiveAccount();

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/home',
        account: activeAccount,
      });
      localStorage.clear();
    } else {
      this.authService.logoutRedirect({
        account: activeAccount,
        postLogoutRedirectUri: '/home'
      });
      localStorage.clear();
    }
  }
  logoutFromFrontChannel() {
    // Clear MSAL cache
    // Clear the MSAL instance's active account
    this.authService.instance.setActiveAccount(null);

    // Clear local storage/session storage
    localStorage.removeItem('login-info');
    localStorage.removeItem('expireOn');
    localStorage.removeItem('logged_in_user');

    // Update UI state
    this.setLoginInfo({});
  }

  hasUserPermission() {
    return this.userService.getUserAuthorization("tag");
  }
}
