<app-loader></app-loader>

<!--wrapper-Landing Page-->
<div *ngIf="!loading">
    <div class="wrapper_landing_page" *ngIf="nuggetData">
        <div class="wrapper_space">
        <div class="container-fluid">
            <!--breadcrumb-->
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a style="cursor: pointer;" class="active" (click)="redirectToList()"><i class="bi bi-house-door"></i> Home</a>
                    </li>
                    <li class="breadcrumb-item" aria-current="page">{{nuggetData.title}}</li>
                </ol>
            </nav>
            <!--breadcrumb-END-->

            <div class="row">
                <!--grid--left-->
                <div class="col-lg-9 col-md-7 col-12 hr-right-1 mb-5 px-lg-5">
                    <div class="hr">
                        <p class="m-0 font-size-12 text-blue-light">Contributor:
                            <ng-container *ngIf="nuggetData.nuggetAuthors">
                                <span style="cursor: pointer;"
                                    *ngFor="let author of nuggetData.nuggetAuthors; let last = last" class="fw-bold">
                                    {{ author.name }} ({{ author.title }})
                                    <ng-container *ngIf="!last">,&nbsp;</ng-container>
                                </span>
                            </ng-container>
                            - Published on: <span class="fw-bold">{{nuggetData.approvedDate | date: 'shortDate'}}</span>
                        </p>

                        <h3 class="font-size-30 mt-2">{{nuggetData.title}}</h3>
                        <p *ngIf="nuggetData.subTitle" class="description-text">
                            {{nuggetData.subTitle}}
                        </p>
                        <div class="mt-3">
                            <span class="gray-label" *ngFor="let tags of nuggetData.nuggetTags" style="cursor: pointer;"
                                >{{tags.title}}</span>
                            <span class="gray-label" *ngFor="let keyword of nuggetData.nuggetKeywords"
                                style="cursor: pointer;" >{{keyword.title}}</span>

                        </div>
                        
                    </div>
                    <div class="my-4"
                    *ngIf="(nuggetData.formatType == 1 || nuggetData.formatType == 2) &&  nuggetData.thumbnail">
                    <img [src]="getNuggetThumbnail()" style="width: 100%; height: 239px;">
                </div>
                <div class="my-4" *ngIf="nuggetData.formatType == 2">
                    <audio preload="none" controls class="img-100">
                        <source [src]="getNuggetMedia()" />
                    </audio>
                </div>
                <div class="my-4" *ngIf="nuggetData.formatType == 3  &&  nuggetData.media">
                    <video preload="none" controls class="img-100">
                        <source [src]="getNuggetMedia()" />
                    </video>
                </div>
                <div *ngIf="(nuggetData.formatType == 2 || nuggetData.formatType == 3 ) && nuggetData.transcript ">
                    <h5>Transcript:</h5>
                    <p >
                        {{nuggetData.transcript}}
                    </p>
                </div>

                <p *ngIf="nuggetData.shortDescription" class="description-text">
                    {{nuggetData.shortDescription}}
                </p>
                <!--pandemic_card-->
                <div *ngIf="nuggetData.textType == 1">
                    <p class="description-text">
                        {{nuggetData.description}}
                    </p>
                </div>
                <div *ngIf="nuggetData.textType == 2 && !nuggetData.isMultilineDescription">
                    <ng-container *ngIf="nuggetData.isOrderedList">
                        <div class="pandemic_card" *ngFor="let descLine of nuggetData.nuggetDescriptionLines; let i = index;">
                            <div class="pandemic_card_circle-no-checkmark">{{i+ 1}}.</div>
                            <p>
                                {{descLine.description}}
                            </p>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!nuggetData.isOrderedList">
                        <div class="pandemic_card" *ngFor="let descLine of nuggetData.nuggetDescriptionLines; let i = index;">
                            <div class="pandemic_card_circle">
                                
                            </div> 
                            <p>
                                {{descLine.description}}
                            </p>
                        </div>
                    </ng-container>
                    
                </div>
                
                <div *ngIf="nuggetData.textType == 2 && nuggetData.isMultilineDescription">
                    <ul [class]="getDescriptionLineClass(nuggetData.nuggetDescriptionLines)">
                        <li *ngFor="let descLine of nuggetData.nuggetDescriptionLines; let i = index;">
                            {{descLine.description}}
                            <ul *ngIf="descLine.childLines.length > 0" [class]="getDescriptionChildLineClass(nuggetData.nuggetDescriptionLines)">
                                <li *ngFor="let cLine of descLine.childLines; let j = index;">
                                    {{cLine.description}}
                                </li>
                            </ul>     
                        </li>
                    </ul>
                </div>
                    <!--pandemic_card-->
                    <div class="mt-4">
                        <!-- <h5 class="text__size__14">Submitted By:
                        <span>
                            <a class="text-blue fw-normal">{{nuggetData.submittedByUser}} </a>
                        </span>
                    </h5> -->




                        <!--Hazard Type Start-->
                        <h5 class="mt-3 text__size__14"> Source:
                            <span>
                                <a class="text-blue fw-normal" target="_blank" [href]="nuggetData.referenceLink">
                                    {{nuggetData.referenceLink}}
                                </a>
                            </span>
                        </h5>

                        <!--Hazard Type Start-->
                        <h5 class="mt-3 text__size__14">Hazard Type:
                            <span>
                                <ng-container
                                    *ngFor="let hazardType of hazardTypeList.slice(0, showAllHazardTypes ? hazardTypeList.length : 2); let i = index; let last = last">
                                    <a style="cursor: pointer;" class="text-blue fw-normal">
                                        {{hazardType.name}}
                                    </a>
                                    {{!last ? ', ' : ''}}
                                </ng-container>
                            </span>
                            <a *ngIf="hazardTypeList.length > 2" class="view_more__btn">
                                {{ showAllHazardTypes ? 'View Less' : 'View More' }} <i class="bi bi-chevron-down"></i>
                            </a>
                        </h5>


                        <!--Cluster Start-->
                        <h5 class="mt-3 text__size__14">Hazard Cluster:
                            <span>
                                <ng-container
                                    *ngFor="let cluster of hazardClusterList.slice(0, showAllHazardClusters ? hazardClusterList.length : 2); let i = index; let last = last">
                                    <a style="cursor: pointer;" class="text-blue fw-normal">
                                        {{cluster.name}}
                                    </a>
                                    {{!last ? ', ' : ''}}
                                </ng-container>
                            </span>
                            <a *ngIf="hazardTypeList.length > 2" class="view_more__btn" (click)="toggleClusterView()">
                                {{ showAllHazardClusters ? 'View Less' : 'View More' }} <i
                                    class="bi bi-chevron-down"></i>
                            </a>
                        </h5>


                        <!--Hazard Start-->
                        <h5 class="mt-3 text__size__14">Hazards:
                            <span>
                                <ng-container
                                    *ngFor="let hazard of hazardList.slice(0, showAllHazards ? hazardList.length : 2); let i = index; let last = last">
                                    <a style="cursor: pointer;" class="text-blue fw-normal">
                                        {{hazard.name}}
                                    </a>
                                    {{!last ? ', ' : ''}}
                                </ng-container>
                            </span>
                            <a *ngIf="hazardList.length > 2" class="view_more__btn" (click)="toggleHazardView()">
                                {{ showAllHazards ? 'View Less' : 'View More' }} <i class="bi bi-chevron-down"></i>
                            </a>
                        </h5>


                        <!--Pillars Start-->
                        <h5 class="mt-3 text__size__14">Pillars:
                            <span>
                                <ng-container
                                    *ngFor="let pillar of pillarList.slice(0, showAllPillars ? pillarList.length : 2); let i = index; let last = last">
                                    <a style="cursor: pointer;" class="text-blue fw-normal">
                                        {{pillar.name}}
                                    </a>
                                    {{!last ? ', ' : ''}}
                                </ng-container>
                            </span>
                            <a *ngIf="pillarList.length > 2" class="view_more__btn " (click)="togglePillarsView()">
                                {{ showAllPillars ? 'View Less' : 'View More' }} <i class="bi bi-chevron-down"></i>
                            </a>
                        </h5>


                        <!--Technical Area Start-->
                        <h5 class="mt-3 text__size__14">IHR Technical Area:
                            <span>
                                <ng-container
                                    *ngFor="let techArea of technicalAreaList.slice(0, showAllTechnicalArea ? technicalAreaList.length : 2); let i = index; let last = last">
                                    <a style="cursor: pointer;" class="text-blue fw-normal">
                                        {{techArea.name}}
                                    </a>
                                    {{!last ? ', ' : ''}}
                                </ng-container>
                            </span>
                            <a *ngIf="technicalAreaList.length > 2" class="view_more__btn"
                                (click)="toggleTechnicalAreasView()">
                                {{ showAllTechnicalArea ? 'View Less' : 'View More' }} <i
                                    class="bi bi-chevron-down"></i>
                            </a>
                        </h5>


                        <!--Technical Area Start-->
                        <h5 class="mt-3 text__size__14">Indicators:
                            <span>
                                <ng-container
                                    *ngFor="let indicator of indicatorsList.slice(0, showAllIndicators ? indicatorsList.length : 2); let i = index; let last = last">
                                    <a style="cursor: pointer;" class="text-blue fw-normal">
                                        {{indicator.name}}
                                    </a>
                                    {{!last ? ', ' : ''}}
                                </ng-container>
                            </span>
                            <a *ngIf="indicatorsList.length > 2" class="view_more__btn"
                                (click)="toggleIndicatorsView()">
                                {{ showAllIndicators ? 'View Less' : 'View More' }} <i class="bi bi-chevron-down"></i>
                            </a>
                        </h5>


                        <!--WHO Benchmarks Start-->
                        <h5 class="mt-3 text__size__14">WHO BenchMarks:
                            <span>
                                <ng-container
                                    *ngFor="let benchMark of benchmarksList.slice(0, showAllWhoBenchmarks ? benchmarksList.length : 2); let i = index; let last = last">
                                    <a style="cursor: pointer;" class="text-blue fw-normal">
                                        {{benchMark.name}}
                                    </a>
                                    {{!last ? ', ' : ''}}
                                </ng-container>
                            </span>
                            <a *ngIf="benchmarksList.length > 2" class="view_more__btn"
                                (click)="toggleWHOBenchmarksView()">
                                {{ showAllWhoBenchmarks ? 'View Less' : 'View More' }} <i
                                    class="bi bi-chevron-down"></i>
                            </a>
                        </h5>



                        <!--IHR Capacity Start-->
                        <h5 class="mt-3 text__size__14">IHR Capacities:
                            <span>
                                <ng-container
                                    *ngFor="let capacity of ihrCapacityList.slice(0, showAllCapacities ? ihrCapacityList.length : 2); let i = index; let last = last">
                                    <a style="cursor: pointer;" class="text-blue fw-normal">
                                        {{capacity.name}}
                                    </a>
                                    {{!last ? ', ' : ''}}
                                </ng-container>
                            </span>
                            <a *ngIf="ihrCapacityList.length > 2" class="view_more__btn"
                                (click)="toggleIhrCapacitiesView()">
                                {{ showAllCapacities ? 'View Less' : 'View More' }} <i class="bi bi-chevron-down"></i>
                            </a>
                        </h5>


                        <!--Regions Start-->
                        <h5 class="mt-3 text__size__14">Regions:
                            <span>
                                <ng-container
                                    *ngFor="let region of regionList.slice(0, showAllRegions ? regionList.length : 2); let i = index; let last = last">
                                    <a style="cursor: pointer;" class="text-blue fw-normal">
                                        {{region.name}}
                                    </a>
                                    {{!last ? ', ' : ''}}
                                </ng-container>
                            </span>
                            <a *ngIf="regionList.length > 2" class="view_more__btn" (click)="toggleRegionView()">
                                {{ showAllRegions ? 'View Less' : 'View More' }} <i class="bi bi-chevron-down"></i>
                            </a>
                        </h5>


                        <!--Countries Start-->
                        <h5 class="mt-3 text__size__14">Countries:
                            <span>
                                <ng-container
                                    *ngFor="let country of countryList.slice(0, showAllCountries ? countryList.length : 2); let i = index; let last = last">
                                    <a style="cursor: pointer;" class="text-blue fw-normal">
                                        {{country.name}}
                                    </a>
                                    {{!last ? ', ' : ''}}
                                </ng-container>
                            </span>
                            <a *ngIf="countryList.length > 2" class="view_more__btn" (click)="toggleCountryView()">
                                {{ showAllCountries ? 'View Less' : 'View More' }} <i class="bi bi-chevron-down"></i>
                            </a>
                        </h5>


                        <!--Sources Start-->
                        <h5 class="mt-3 text__size__14">Sources:
                            <span>
                                <ng-container
                                    *ngFor="let source of nokSourceList.slice(0, showAllSources ? nokSourceList.length : 2); let i = index; let last = last">
                                    <a style="cursor: pointer;" class="text-blue fw-normal">
                                        {{source.name}}
                                    </a>
                                    {{!last ? ', ' : ''}}
                                </ng-container>
                            </span>
                            <a *ngIf="nokSourceList.length > 2" class="view_more__btn" (click)="toggleSourceView()">
                                {{ showAllSources ? 'View Less' : 'View More' }} <i class="bi bi-chevron-down"></i>
                            </a>
                        </h5>


                        <!--Population Start-->
                        <h5 class="mt-3 text__size__14">Population:
                            <span>
                                <ng-container
                                    *ngFor="let population of populationList.slice(0, showAllPopulations ? populationList.length : 2); let i = index; let last = last">
                                    <a style="cursor: pointer;" class="text-blue fw-normal">
                                        {{population.name}}
                                    </a>
                                    {{!last ? ', ' : ''}}
                                </ng-container>
                            </span>
                            <a *ngIf="populationList.length > 2" class="view_more__btn"
                                (click)="togglePopulationView()">
                                {{ showAllPopulations ? 'View Less' : 'View More' }} <i class="bi bi-chevron-down"></i>
                            </a>
                        </h5>




                        <!-- <h5 class="mb-2">Population: <span><a class="text-blue fw-normal" >{{getPopulationTypes()}} </a></span></h5>

                    <h5 class="mb-2">NOK Source: <span><a class="text-blue fw-normal" >{{getNokSources()}} </a></span></h5> -->
                        <div class="hr my-4"></div>
                        <button class="btn btn-blue" (click)="redirectToList()">
                            <i class="bi bi-arrow-left"></i> Back
                        </button>
                    </div>

                </div>
                <!--grid--left-END-->

                <!--grid--right-->
                <!--grid--right-END-->
            </div>

        </div>


    </div>

</div>
</div>