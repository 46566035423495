import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AddLikedNuggetDataModel, DeleteLikedNuggetDataModel, GetLikedNuggetDataModel, NuggetAuthor, NuggetHazardType, NuggetKeyword, NuggetTag, Nuggets, SetDownloadNuggetDataModel, SetSharedNuggetDataModel, SetViewedNuggetDataModel, TaxonomyForLabel, UpdateLikedNuggetDataModel } from 'src/app/core/models/nuggets/nuggets.model';
import { CommonHttpService } from 'src/app/core/services/common-http/common-http.service';
import { LoggedInUser } from 'src/app/core/models/user/loggedInUser.model';
import { LoginService } from 'src/app/core/services/login/login.service';
import { LikeNokService } from 'src/app/core/services/like-nok/like-nok.service';
import { NokService } from 'src/app/core/services/nok/nok.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShareNokService } from 'src/app/core/services/share-nok/share-nok.service';
import { SharableLinkComponent } from '../../sharable-link/sharable-link.component';
import html2pdf from 'html2pdf.js';
import { GetNuggetByIdModel, NuggetDescriptionLine, RelatedCategory } from 'src/app/core/models/category/get-category.model';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { LoaderComponent } from '../../loader/loader.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-nugget-detail-para',
  standalone: true,
  imports: [CommonModule, LoaderComponent],
  templateUrl: './nugget-detail-para.component.html',
  styleUrls: ['./nugget-detail-para.component.scss']
})
export class NuggetDetailParaComponent implements OnInit ,AfterViewInit {

  nuggetId: number;
  loggedInUser: LoggedInUser;
  sharedData: SetSharedNuggetDataModel;
  viewedData: SetViewedNuggetDataModel;
  likedData: GetLikedNuggetDataModel;
  deleteLikeData: DeleteLikedNuggetDataModel;
  updateLikeData: UpdateLikedNuggetDataModel;
  addLikeData: AddLikedNuggetDataModel;
  isLiked: boolean = false;
  isDisliked: boolean = false;
  nuggetData: Nuggets;
  downloadData: SetDownloadNuggetDataModel;
  loading:boolean=true;
  

  // taxonomy
  hazardTypeList: TaxonomyForLabel[] = [];
  hazardClusterList: TaxonomyForLabel[] = [];
  hazardList: TaxonomyForLabel[] = [];

  pillarList: TaxonomyForLabel[] = [];
  technicalAreaList: TaxonomyForLabel[] = [];
  indicatorsList: TaxonomyForLabel[] = [];

  benchmarksList: TaxonomyForLabel[] = [];
  ihrCapacityList: TaxonomyForLabel[] = [];

  regionList: TaxonomyForLabel[] = [];
  countryList: TaxonomyForLabel[] = [];

  nokSourceList: TaxonomyForLabel[] = [];
  populationList: TaxonomyForLabel[] = [];

  showAllHazardTypes: boolean = false;
  showAllHazardClusters: boolean = false;
  showAllHazards: boolean = false;

  showAllPillars: boolean = false;
  showAllTechnicalArea: boolean = false;
  showAllIndicators: boolean = false;

  showAllWhoBenchmarks: boolean = false;
  showAllCapacities: boolean = false;

  showAllRegions: boolean = false;
  showAllCountries: boolean = false;

  showAllSources: boolean = false;
  showAllPopulations: boolean = false;




  nuggetdownloadUrl: string;
  downloadMediaUrl: any;
  nuggetImage: any;
  content:any;
  imageLoaded: boolean =false;
  constructor
    (
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private commonHttpService: CommonHttpService,
      private loginService: LoginService,
      private sanitizer: DomSanitizer,
      private modalService: NgbModal,
      private nokService: NokService,
      private shareService: ShareNokService,
      private likeDislikeService: LikeNokService,
      private route: ActivatedRoute,
      private loaderService: LoaderService,
      private toastrService: ToastrService
    ) {

  }

  ngOnInit(): void {
    this.getNuggetId();
    this.loginService.getLoginInfo().subscribe((data) => {
      this.loggedInUser = data;
    })
    const sharedParam = this.route.snapshot.queryParams['shared'];
    const loggedInUserId = this.loggedInUser.userId;
    const nuggetId = this.nuggetId;
    this.shareService.handleSharedParams(sharedParam, loggedInUserId, nuggetId).subscribe(
      (result) => {
        if (result !== null) {
          this.removeSharedQueryParam();
        }
      }
    )
    this.getLikedData();
  }
  ngAfterViewInit() {
   
  }

  getNuggetId() {
    this.activatedRoute.params.subscribe({
      next: (data: any) => {
        this.nuggetId = data.id
        this.getNuggetData();
    
      },
      error: () => {}
    });
  }

  getNuggetData() {
    let model: GetNuggetByIdModel =
    {
      nuggetId: this.nuggetId,
      includeRelatedDetails: true
    }
    this.loaderService.show();
    this.nokService.getNuggetById(model).subscribe({
      next: (res: Nuggets) => {
        this.loaderService.hide();
        this.nuggetData = res;
        if (!this.nuggetData.thumbnail && this.nuggetData.nuggetSourceTypes) {
          const sourceWithThumbnail = this.nuggetData.nuggetSourceTypes.find(x => x.thumbnail);
          if (sourceWithThumbnail) {
            this.nuggetData.thumbnail = sourceWithThumbnail.thumbnail;
          }
        }

        this.nuggetData.relatedNuggets =  this.nuggetData.relatedNuggets.slice(0, 3);
        this.buildTaxonomy();
        
        if (this.nuggetData.formatType === 1 && this.nuggetData.textType === 2) {
          const descriptionLines = this.nuggetData.nuggetDescriptionLines;
          if (descriptionLines.length > 0) {
            this.nuggetData.isOrderedList = descriptionLines[0].isOrdered;
            this.nuggetData.isMultilineDescription = descriptionLines.some(line => line.childLines.length > 0);
          }
        }
        this.loading=false;

        // add view data entry.
        this.updateNuggetViewCount();
      },
      error: (err: any) => {
        this.loaderService.hide();
        this.loading=false;
      }
    })
    
  }

   getNuggetThumbnail() {
    let thumbnail = `${environment.storageUrl}/${environment.containerName}/${environment.nuggetThumbnailPath}/${this.nuggetData.thumbnail}`;
    return thumbnail;
  }

    async getXMLUrl(url : any) {
    //Convert to base64
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = () => {
        reject({
          statusText: xhr.statusText,
        });
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    });
  };
  
  getNuggetMedia() {
    this. downloadMediaUrl= `${environment.storageUrl}/${environment.containerName}/${environment.nuggetMediaPath}/${this.nuggetData.media}`;
    return this. downloadMediaUrl;
  }

  getLikedData() {
    if (this.loggedInUser === null) {
      return;
    }
    this.likedData = {
      userId: this.loggedInUser.userId,
      nuggetId: this.nuggetId

    }
    this.nokService.getLikedNuggetData(this.likedData).subscribe(
      (data) => {
        if (data.isLiked === true) {
          this.isLiked = true;
        }
        else {
          this.isDisliked = true;
        }
      },
      (error) => {
        if (error.status === 404) {
          return;
        }
      }
    )
  }

  getDownloadData() {
    
    this.downloadData = {
      userId: this.loggedInUser.userId,
      nuggetId: this.nuggetId ,
      downloadDate: new Date()

    }
    this.nokService.setDownloadNuggetData(this.downloadData).subscribe() ;

  }

  setSharedCount() {
    this.sharedData = {
      userId: this.loggedInUser.userId,
      nuggetId: this.nuggetId ,
      viewDate: new Date()
    }
    this.nokService.setSharedNuggetData(this.sharedData).subscribe();
  }

  updateNuggetViewCount() {
    this.viewedData = {
      userId: this.loggedInUser.userId,
      nuggetId: this.nuggetId ,
      viewDate: new Date()

    }
    this.nokService.setViewedNuggetData(this.viewedData).subscribe({
      next: (data: any) => {},
      error: (error: Error) => console.error(error)
    });
  }

  LikeClick() {
    this.likeDislikeService.handleLikeDislike(this.isLiked, this.isDisliked, this.nuggetId, this.loggedInUser.userId, 'like')
      .subscribe((success) => {
        if (success) {
          this.isLiked = !this.isLiked;
          this.isDisliked = false;
        }
      });
  }

  DislikeClick() {
    this.likeDislikeService.handleLikeDislike(this.isLiked, this.isDisliked, this.nuggetId, this.loggedInUser.userId, 'dislike')
      .subscribe((success) => {
        if (success) {
          this.isDisliked = !this.isDisliked;
          this.isLiked = false;
        }
      });
  }

  redirectToprevious() {
    this.router.navigateByUrl('/home');
  }

  removeSharedQueryParam() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { shared: null },
      queryParamsHandling: 'merge'
    });
  }

  openShareModal() {
    this.setSharedCount();
    const modalRef = this.modalService.open(SharableLinkComponent, { size: '', backdrop: 'static' });
    modalRef.componentInstance.url = `${environment.siteUrl}` + this.router.url;
  }

  getcontextById(id: number): string {
    switch (id) {
      case 1:
        return "Refugee Context";
      case 2:
        return "Rural/Urban";
      case 3:
        return "Humanitarian";
      case 4:
        return "Gender Based";
      default:
        return ""; // Default case if the ID doesn't match any of the specified cases
    }
  }
  onImageLoad() {
    this.imageLoaded = true;
  }

    downloads() {
      this.getDownloadData();
      if(this.nuggetData.formatType==3 ||this.nuggetData.formatType==2 )
        {
            const url = this. downloadMediaUrl
           const a = document.createElement('a');
           a.href = url;
           a.download = this.nuggetData.media;
           document.body.appendChild(a);
           a.click();  
           document.body.removeChild(a);
        }
      else
      {
        // Helper function to sanitize and truncate title
        const sanitizeTitle = (title: string) => {
          const illegalChars = /[\/\?<>\\:\*\|":]/g; // Regex to match illegal characters
          let sanitizedTitle = title.replace(illegalChars, '_'); // Replace illegal characters with '_'
          if (sanitizedTitle.length > 10) {
            sanitizedTitle = sanitizedTitle.substring(0, 10); // Truncate to 10 characters
          }
          return sanitizedTitle;
        };

        const sanitizedTitle = sanitizeTitle(this.nuggetData.title);

        //  call to downlad API.
        const url = `${environment.serverUrl}/Nugget/DownloadNOK?nuggetId=${this.nuggetData.nuggetId}`;
        this.loaderService.show();
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then(response => {
            this.loaderService.hide();

            if (!response.ok) {
              this.toastrService.error("Something went wrong while downloading the file. Please try again later.")
            }
            return response.blob();
          })
          .then(blob => {
            this.loaderService.hide();

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `NoK_download_${sanitizedTitle}_${new Date().getTime()}.pdf`; // Set the desired file name and extension
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          })
          .catch(error => {
            this.loaderService.hide();
            this.toastrService.error("Something went wrong while downloading the file. Please try again later.")
          });
      }
    }

    
  
  buildTaxonomy() {
    this.nuggetData.nuggetHazardTypes.forEach(hazardType => {
      this.hazardTypeList.push({
        id: hazardType.hazardTypeId,
        name: hazardType.name,
        type: "HazardTypes"
      });

      hazardType.nuggetHazardClusters.forEach(cluster => {
        this.hazardClusterList.push({
          id: cluster.hazardClusterId,
          name: cluster.name,
          type: "HazardClusters"
        });

        cluster.nuggetHazardValues.forEach(value => {
          this.hazardList.push({
            id: value.hazardValueId,
            name: value.name,
            type: "HazardValues"
          });
        });
      });
    });

    this.nuggetData.nuggetSourceTypes.forEach(source => {
      this.nokSourceList.push({
        id: source.nokSourceTypeId,
        name: source.name,
        type: "NokSource"
      });
    });

    this.nuggetData.nuggetPersonTypes.forEach(person => {
      this.populationList.push({
        id: person.personTypeId,
        name: person.name,
        type: "Population"
      });

    });
    this.nuggetData.nuggetRegions.forEach(region => {
      this.regionList.push({
        id: region.regionId,
        name: region.name,
        type: "Region"
      });

      region.nuggetCountries.forEach(country => {
        this.countryList.push({
          id: country.countryId,
          name: country.name,
          type: "Country"
        });
      });
    });

    this.nuggetData.nuggetIHRTechnicalAreas.forEach(techArea => {
      this.pillarList.push({
        id: techArea.ihrTechnicalAreaId,
        name: techArea.name,
        type: "IHRTechnicalAreas"
      });

      techArea.nuggetIHRLevels.forEach(ihrLevel => {
        this.technicalAreaList.push({
          id: ihrLevel.ihrLevelId,
          name: ihrLevel.name,
          type: "IHRLevels"
        });

        ihrLevel.nuggetIHRSpecificLevels.forEach(indicator => {
          this.indicatorsList.push({
            id: indicator.ihrSpecificLevelId,
            name: indicator.name,
            type: "IHRSpecificLevels"
          });
        });
      });
    });

    this.nuggetData.nuggetIHRCapacities.forEach(capacity => {
      this.benchmarksList.push({
        id: capacity.ihrCapacityId,
        name: capacity.name,
        type: "IHRCapacities"
      });

      capacity.nuggetIHRSpecificCapacities.forEach(speccapacity => {
        this.ihrCapacityList.push({
          id: speccapacity.ihrSpecificCapacityId,
          name: speccapacity.name,
          type: "IHRSpecificCapacities"
        });
      });
    });
  }

  getRelatedNuggetIcon(formatType: number) {
    let className = "bi bi-journal-text";
    switch (formatType) {
      case 2:
        className = "bi bi-volume-up";
        break;
      case 3:
        className = "bi bi-play-btn";
        break;
    }
    return className;
  }
  getRelatedNuggetIconText(formatType: number) {
    let iconText = "TEXT";
    switch (formatType) {
      case 2:
        iconText = "AUDIO";
        break;
      case 3:
        iconText = "VIDEO";
        break;
    }
    return iconText;
  }
  getRelatedNuggetDivClass(formatType: number) {
    let divClass = "icons circle_text";
    switch (formatType) {
      case 2:
        divClass = "icons circle_audio";
        break;
      case 3:
        divClass = "icons circle_video";
        break;
    }
    return divClass;
  }
  
  getRelatedNuggetTaxonomy(relatedNugget: Nuggets) {
    const topNumber: number = 1;
    const taxonomyList: TaxonomyForLabel[] = [];

    const addTaxonomy = (id: number, type: string, name: string) => {
      taxonomyList.push({ id, type, name });
    };
    relatedNugget.nuggetRegions.slice(0, topNumber).forEach(region => {
      addTaxonomy(region.regionId, 'Region', region.name);

      region.nuggetCountries.slice(0, topNumber).forEach(country => {
        addTaxonomy(country.countryId, 'Country', country.name);
      });
    });
    relatedNugget.nuggetHazardTypes.slice(0, topNumber).forEach(hazardType => {
      addTaxonomy(hazardType.hazardTypeId, 'HazardTypes', hazardType.name);

      hazardType.nuggetHazardClusters.slice(0, topNumber).forEach(cluster => {
        addTaxonomy(cluster.hazardClusterId, 'HazardClusters', cluster.name);

        cluster.nuggetHazardValues.slice(0, topNumber).forEach(value => {
          addTaxonomy(value.hazardValueId, 'HazardValues', value.name);
        });
      });
    });

    relatedNugget.nuggetIHRTechnicalAreas.slice(0, topNumber).forEach(techArea => {
      addTaxonomy(techArea.ihrTechnicalAreaId, 'IHRTechnicalAreas', techArea.name);

      techArea.nuggetIHRLevels.slice(0, topNumber).forEach(ihrLevel => {
        addTaxonomy(ihrLevel.ihrLevelId, 'IHRLevels', ihrLevel.name);

        ihrLevel.nuggetIHRSpecificLevels.slice(0, topNumber).forEach(indicator => {
          addTaxonomy(indicator.ihrSpecificLevelId, 'IHRSpecificLevels', indicator.name);
        });
      });
    });

    relatedNugget.nuggetIHRCapacities.slice(0, topNumber).forEach(capacity => {
      addTaxonomy(capacity.ihrCapacityId, 'IHRCapacities', capacity.name);

      capacity.nuggetIHRSpecificCapacities.slice(0, topNumber).forEach(speccapacity => {
        addTaxonomy(speccapacity.ihrSpecificCapacityId, 'IHRSpecificCapacities', speccapacity.name);
      });
    });

    return taxonomyList;
  }

  toggleHazardTypeView() {
    this.showAllHazardTypes = !this.showAllHazardTypes;
  }
  toggleClusterView() {
    this.showAllHazardClusters = !this.showAllHazardClusters;
  }
  toggleHazardView() {
    this.showAllHazards = !this.showAllHazards;
  }
  togglePillarsView() {
    this.showAllPillars = !this.showAllPillars;
  }

  toggleTechnicalAreasView() {
    this.showAllTechnicalArea = !this.showAllTechnicalArea;
  }
  toggleIndicatorsView() {
    this.showAllIndicators = !this.showAllIndicators;
  }
  toggleWHOBenchmarksView() {
    this.showAllWhoBenchmarks = !this.showAllWhoBenchmarks;
  }
  toggleIhrCapacitiesView() {
    this.showAllCapacities = !this.showAllCapacities;
  }
  toggleRegionView() {
    this.showAllRegions = !this.showAllRegions;
  }
  toggleCountryView() {
    this.showAllCountries = !this.showAllCountries;
  }

  toggleSourceView() {
    this.showAllSources = !this.showAllSources;
  }

  togglePopulationView() {
    this.showAllPopulations = !this.showAllPopulations;
  }
  addCategoryToFilter(taxoomy: TaxonomyForLabel) {
    localStorage.setItem('filterTaxoomy', JSON.stringify(taxoomy));
    this.redirectToprevious();
  }
  addAuthorToFilter(author: NuggetAuthor) {
    let taxoomy: TaxonomyForLabel = {
      id:0,
      name: author.name,
      type: 'Author'
    }
    localStorage.setItem('filterTaxoomy', JSON.stringify(taxoomy));
    this.redirectToprevious();
  }

  addTagToFilter(tag: NuggetTag) {
    let taxoomy: TaxonomyForLabel = {
      id:0,
      name: tag.title,
      type: 'Tag'
    }
    localStorage.setItem('filterTaxoomy', JSON.stringify(taxoomy));
    this.redirectToprevious();
  }
  addKeywordToFilter(keyword: NuggetKeyword) {
    let taxoomy: TaxonomyForLabel = {
      id:0,
      name: keyword.title,
      type: 'Keyword'
    }
    localStorage.setItem('filterTaxoomy', JSON.stringify(taxoomy));
    this.redirectToprevious();
  }
  navigateToNugget(nugget: Nuggets) {
    this.router.navigate(['/home/nok-details', nugget.nuggetId]);
  }
  getDescriptionLineClass(descLines: any[]) {
      return descLines.some(x => !x.isOrdered) ? 'unorder-list' : 'order-list';
  }
  
  getDescriptionChildLineClass(descLines: any[]) {
    return descLines.some(x => !x.isOrdered) ? 'unorder-list-sub-list' : 'order-list order-sub-list';
  }
}

