<app-loader></app-loader>
<div class="edit_user__header">
    <div>
        <i class="bi bi-grid pe-1 cursor-pointer"></i> {{ queryNuggetId > 0 ? 'Edit': 'Add'}} a NoK
    </div>
    <div><i id="createNok_BackNuggetList" class="bi bi-x-lg cursor-pointer" (click)="redirectToNuggetList()"></i></div>
</div>

<div class="p-4">
    <div class="row" *ngIf="manageNugget.reason && (manageNugget.status == 4 || manageNugget.status == 5)">
        <span class="text-danger">
            <h6><span style="text-decoration: underline;">{{manageNugget.status == 4? 'Reason for return':'Reason for rejection'}}</span>: {{manageNugget.reason}} </h6>
        </span>
    </div>
    <!--wizard-->
    <div class="wizard">
        <ul class="nav nav-tabs justify-content-center border-0" id="myTab" role="tablist">
            <li class="nav-item">
                <button id="createNok_Step1" [class]="isStepActive(1) ? 'active' : ''" id="home-tab" type="button" (click)="openStep(1)">
                    <i class="bi bi-check-circle-fill check-step"></i>
                    <span class="step">Step 1</span>
                    <span class="step_categories">General Details</span>
                </button>
            </li>
            <li class="nav-item">
                <button id="createNok_Step2" [class]="isStepActive(2) ? 'active' : ''" id="profile-tab" type="button" (click)="openStep(2)">
                    <i class="bi bi-check-circle-fill check-step"></i>
                    <span class="step">Step 2</span>
                    <span class="step_categories">Description</span>
                </button>
            </li>
            <li class="nav-item">
                <button id="createNok_Step3" [class]="isStepActive(3) ? 'active' : ''" id="profile-tab" type="button" (click)="openStep(3)">
                    <i class="bi bi-check-circle-fill check-step"></i>
                    <span class="step">Step 3</span>
                    <span class="step_categories">Taxonomy</span>
                </button>
            </li>
            <li class="nav-item">
                <button id="createNok_Step4" [class]="isStepActive(4) ? 'active' : ''" id="contact-tab" type="button" (click)="openStep(4)">
                    <i class="bi bi-check-circle-fill check-step"></i>
                    <span class="step">Step 4</span>
                    <span class="step_categories">Author Details</span>
                </button>
            </li>
        </ul>
        <div class="tab-content mt-4" id="myTabContent">
            <!--Step 1 starts-->
            <div class="tab-pane fade" id="profile-tab-pane" [class]="currentStep === 1 ? 'fade active show':''">
                <form #step1Form="ngForm">
                    <div class="row">
                        <div class="col-md-6 col-12 mb-3">
                            <div class="custom-input-lg">
                                <label for="txtNuggetTitle" class="form-label">NoK Title <span
                                        class="text-danger">*</span></label>

                                <input type="text" id="txtNuggetTitle" name="NuggetTitle"
                                    [(ngModel)]="manageNugget.title" class="form-control" placeholder="Nugget Title"
                                    required #nuggetTitle="ngModel" [disabled]="isUpdateDisabled()">
                                <div *ngIf="!manageNugget.title && (nuggetTitle.dirty || nuggetTitle.touched)"
                                    class="text-danger">
                                    NoK Title is required
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12 mb-3">
                            <div class="custom-input-lg">
                                <label for="txtSubTitle" class="form-label">SubTitle </label>

                                <input type="text" id="txtSubTitle" name="NuggetSubTitle"
                                    [(ngModel)]="manageNugget.subTitle" class="form-control" placeholder="Sub Title"
                                    #nuggetSubTitle="ngModel" [disabled]="isUpdateDisabled()">

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-12 mb-3">
                            <div class="custom-input-lg">
                                <label for="txtSubTitle" class="form-label">Short Description </label>
                            
                                <textarea class="form-control" style="height: 200px;" name="NuggetShortDescription"
                                    [(ngModel)]="manageNugget.shortDescription"
                                    [required]="isSubmit" #nuggetShortDescription="ngModel"
                                    [disabled]="isUpdateDisabled()"></textarea>
                                <div *ngIf="isSubmit && !manageNugget.shortDescription && (nuggetShortDescription.dirty || nuggetShortDescription.touched)"
                                    class="text-danger">
                                    Short Description is required
                                </div>
                            
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-12 mb-3">
                            <label for="exampleFormControlInput1" class="form-label">Tags</label>

                            <ng-select name="NuggetTags" [items]="tagsSuggestions$ | async" bindLabel="item"
                                [(ngModel)]="manageNugget.nuggetTagsModel" [addTag]="true" [multiple]="true"
                                [selectOnTab]="true" [typeahead]="tagSearch$" placeholder="Type here to search..."
                                [loadingText]="'Loading...'" [typeToSearchText]="'Type to search...'"
                                [disabled]="isUpdateDisabled()">
                            </ng-select>
                        </div>
                        <div class="col-md-6 col-12 mb-3">
                            <label for="exampleFormControlInput1" class="form-label">Keywords </label>
                            <ng-select name="NuggetKeywords" [items]="keywordSuggestions$ | async" bindLabel="item"
                                [(ngModel)]="manageNugget.nuggetKeywordsModel" [addTag]="true" [multiple]="true"
                                [selectOnTab]="true" [typeahead]="keyWordsearch$" placeholder="Type here to search..."
                                [loadingText]="'Loading...'" [typeToSearchText]="'Type to search...'"
                                [disabled]="isUpdateDisabled()">
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-12 mb-3">
                            <label for="exampleFormControlInput1" class="form-label">NoK Source 
                                <span class="text-danger">*</span>

                                <span style="cursor: pointer;" data-toggle="tooltip" data-placement="top"
                                    title="If you can't find the desired nok source, please contact the administrator.">
                                    <i class="bi bi-info-circle-fill ps-1 font-size-16"></i>
                                </span>

                                &nbsp; 
                                <input
                                    class="form-check-input" type="checkbox" id="select-all-noksources" (change)="selectAllNokSource($event)"
                                    [checked]="areAllNokSourceSelected()">
                                <label for="select-all-noksources">
                                    Select All
                                </label>
                            </label>


                            <ng-select name="NuggetSources" [items]="allNokSourceTypes" [multiple]="true"
                                bindLabel="name" [closeOnSelect]="false" bindValue="nokSourceTypeId"
                                [(ngModel)]="selectedNokSourceIds" [required]="isSubmit" #nuggetSources="ngModel"
                                [disabled]="isUpdateDisabled()">
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input class="form-check-input"
                                        [name]="'NuggetSourceTypeItems'+item.nokSourceTypeId" id="item-{{index}}"
                                        type="checkbox" [ngModel]="item$.selected" />
                                    {{item.name}}
                                </ng-template>
                            </ng-select>
                            <div *ngIf="isSubmit && !selectedNokSourceIds?.length && (nuggetSources.dirty || nuggetSources.touched)"
                                class="text-danger">
                                Source Type is required
                            </div>
                        </div>
                        <div class="col-md-6 col-12 mb-3">
                            <label for="exampleFormControlInput1" class="form-label">
                                Population Type 
                                <span class="text-danger">*</span>
                                <span style="cursor: pointer;" data-toggle="tooltip" data-placement="top"
                                    title="If you can't find the desired population type, please contact the administrator.">
                                    <i class="bi bi-info-circle-fill ps-1 font-size-16"></i>
                                </span>

                                &nbsp; 
                                <input
                                    class="form-check-input" type="checkbox" id="select-all-populationType" (change)="selectAllPopulationType($event)"
                                    [checked]="areAllPopulationTypeSelected()">
                                <label for="select-all-populationType">
                                    Select All
                                </label>
    
                            </label>

                            <ng-select name="NuggetPersons" [items]="allPersonTypes" [multiple]="true" bindLabel="name"
                                [closeOnSelect]="false" bindValue="personTypeId" [(ngModel)]="selectedPersonIds"
                                [required]="isSubmit" #nuggetPersons="ngModel" [disabled]="isUpdateDisabled()">
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input class="form-check-input" [name]="'NuggetPersonItems'+item.personTypeId"
                                        id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" />
                                    {{item.name}}
                                </ng-template>
                            </ng-select>
                            <div *ngIf="isSubmit && !selectedPersonIds?.length && (nuggetPersons.dirty || nuggetPersons.touched)"
                                class="text-danger">
                                Population type is required
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <!-- <div class="col-md-3 col-12 mb-3">
                                <div class="custom-input-lg">
                                    <label for="exampleFormControlInput1" class="form-label">Continent</label>
                                    <select class="form-select" name="NuggetContinentId" (change)="onContinentChange()"
                                        [(ngModel)]="manageNugget.continentId">
                                        <option *ngFor="let continent of allContinents" [value]="continent.continentId">{{
                                            continent.name }}</option>
                                    </select>
                                </div>
                            </div> -->

                        <div class="col-md-6 col-12 mb-3">
                            <label for="exampleFormControlInput1" class="form-label">Region <span class="text-danger">*</span> 
                                
                                &nbsp; 
                                <input
                                    class="form-check-input" type="checkbox" id="select-all-regions" (change)="selectAllRegions($event)"
                                    [checked]="areAllRegionsSelected()">
                                <label for="select-all-regions">
                                    Select All
                                </label>

                            </label>

  
  
                            <ng-select name="NuggetRegions" [items]="filteredRegions" [multiple]="true" bindLabel="name"
                                [closeOnSelect]="false" bindValue="regionId" [(ngModel)]="selectedRegionIds"
                                (change)="onRegionChange()" [required]="isSubmit" #nuggetRegions="ngModel"
                                [disabled]="isUpdateDisabled()">
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input class="form-check-input" [name]="'NuggetRegionsItem'+item.regionId"
                                        id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" />
                                    {{item.name}}
                                </ng-template>
                            </ng-select>
                            <div *ngIf="isSubmit && !selectedRegionIds?.length && (nuggetRegions.dirty || nuggetRegions.touched)"
                                class="text-danger">
                                Region is required
                            </div>
                        </div>
                        <div class="col-md-6 col-12 mb-3" *ngIf="filteredCountries.length > 0">
                            <label for="exampleFormControlInput1" class="form-label">Country
                                 <span class="text-danger">*</span>
                            </label>

                            &nbsp; 
                            <input
                                class="form-check-input" type="checkbox" id="select-all-countries" (change)="selectAllCountries($event)"
                                [checked]="areAllCountriesSelected()">
                            <label for="select-all-countries">
                                Select All
                            </label>

                            <ng-select name="NuggetCountriess" [items]="filteredCountries" [multiple]="true"
                                bindLabel="name" [closeOnSelect]="false" bindValue="countryId"
                                [(ngModel)]="selectedCountryIds" (change)="onRegionChange()" [required]="isSubmit"
                                #nuggetCountries="ngModel" [disabled]="isUpdateDisabled()">
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input class="form-check-input" [name]="'NuggetRegionsItem'+item.countryId"
                                        id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" />
                                    {{item.name}}
                                </ng-template>
                            </ng-select>
                            <div *ngIf="isSubmit &&  !selectedCountryIds?.length && (nuggetCountries.dirty || nuggetCountries.touched)"
                                class="text-danger">
                                Country is required
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-4 col-12 mb-3">
                            <div class="custom-input-lg position-relative">
                                <label for="exampleFormControlInput1" class="form-label">Start Date <span
                                        class="text-danger">*</span></label>
                                <input autocomplete="off" name="NuggetStartDate" [(ngModel)]="startDate" type="text"
                                    class="form-control" placeholder="Select Start Date" bsDatepicker
                                    #nuggetStartDate="ngModel" [required]="isSubmit" [disabled]="isUpdateDisabled()">
                                <div *ngIf="isSubmit && nuggetStartDate.invalid && (nuggetStartDate.dirty || nuggetStartDate.touched)"
                                    class="text-danger">
                                    <div *ngIf="nuggetStartDate.errors && nuggetStartDate.errors['required']">
                                        Start date is required
                                    </div>
                                </div>
                                <i class="bi bi-calendar4-week calendar-icon"></i>
                            </div>
                        </div>

                        <div class="col-md-4 col-12 mb-3">
                            <div class="custom-input-lg position-relative">
                                <label for="exampleFormControlInput1" class="form-label">End Date <span
                                        class="text-danger">*</span></label>
                                <input autocomplete="off" name="NuggetEndDate" [(ngModel)]="endDate" type="text"
                                    class="form-control" placeholder="Select End Date" bsDatepicker
                                    #nuggetEndDate="ngModel" [required]="isSubmit && manageNugget.endDateStatus == 0"
                                    [disabled]="isUpdateDisabled()">
                                <div *ngIf="isSubmit && nuggetEndDate.invalid && (nuggetEndDate.dirty || nuggetEndDate.touched)"
                                    class="text-danger">
                                    <div *ngIf="nuggetEndDate.errors && nuggetEndDate.errors['required']">
                                        End date is required
                                    </div>
                                </div>
                                <div *ngIf="!isEndDateValid()" class="text-danger">
                                    End date must be greater than start date
                                </div>
                                <i class="bi bi-calendar4-week calendar-icon"></i>
                            </div>
                        </div>

                        <div class="col-md-4 col-12 mb-3" *ngIf="endDate == null">
                            <div class="custom-input-lg">
                                <label for="exampleFormControlInput1" class="form-label">
                                    Event Clarification <span class="text-danger">*</span>
                                </label>
                                <select class="form-select" name="NuggetEndDateStatus"
                                    [(ngModel)]="manageNugget.endDateStatus"
                                    [required]="isSubmit && manageNugget.endDate==null" #nuggetEnddateStatus="ngModel"
                                    [disabled]="isUpdateDisabled()">
                                    <option value="0">--SELECT--</option>
                                    <option value="1">Ongoing</option>
                                    <option value="2">End date unknown</option>
                                </select>
                                <div *ngIf="isSubmit && (!manageNugget.endDateStatus || manageNugget.endDateStatus == 0) && (nuggetEnddateStatus.dirty || nuggetEnddateStatus.touched)"
                                    class="text-danger">
                                    Event clarification is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="hr my-4"></div>
                <div class="row">
                    <div class=" col-6">

                    </div>
                    <div class="col-6 text-end">
                        <button id="createNok_ValidateStep1" (click)="validateStep(step1Form, 1, true)" class="btn btn-blue mt-2">Next<i
                                class="bi bi-arrow-right ps-1"></i></button>
                    </div>
                </div>
            </div>
            <!--Step 2 starts-->
            <div class="tab-pane fade" id="contact-tab-pane" [class]="currentStep === 2 ? 'fade active show':''">
                <form #step2Form="ngForm">
                    <div class="row">
                            <div class="col-md-4 col-12 mb-3">
                                <div class="custom-input-lg">
                                    <label for="exampleFormControlInput1" class="form-label">Add NoK Thumbnail</label>
                                    <div>
                                        <div class="input-group custom-file-button">
                                            <input type="file" name="NuggetThumbnail" id="avatar-input-file" accept="image/*" #fileInput class="form-control" id="avatar-input-file " (change)="onThumbnailChange($event)" [disabled]="isUpdateDisabled()">
                                            <button id="createNok_CropperOpen" class="btn btn-outline-secondary" type="button" id="inputGroupFileAdd" (click)="openImageModal(uploadModal)" [disabled]="!this.manageNugget.thumbnail" >Crop Image</button>
                                            
                                            <a *ngIf="!isUpdateDisabled() && manageNugget.tempThumbnailPath != ''" style=" cursor: pointer; margin-left: 5px; font-size: 25px;" (click)="removeThumbnail()">
                                                <i class="bi bi-trash3"></i>
                                            </a>
                                    </div>
                                   
                                    <img *ngIf="manageNugget.tempThumbnailPath != ''" [src]="manageNugget.tempThumbnailPath " alt=""
                                              class="img-responsive mt-1" style="max-width: 397px; max-height: 360px;">
                                    
                                        
                                    </div>
                            </div>
                        </div> 
                        <div class="col-md-4 col-12 mb-3">
                            <div class="custom-input-lg">
                                <label for="exampleFormControlInput1" class="form-label"> NoK Format</label>
                                <select class="form-select" name="NuggetFormatType" [required]="isSubmit"
                                    name="NuggetFormatType" [(ngModel)]="manageNugget.formatType" class="form-select"
                                    [required]="isSubmit" #nuggetFormatType="ngModel" [disabled]="isUpdateDisabled()">
                                    <option value="0">--SELECT--</option>
                                    <option value="1">Text</option>
                                    <option value="2">Audio</option>
                                    <option value="3">Video</option>
                                </select>
                                <div *ngIf="isSubmit && (!manageNugget.formatType || manageNugget.formatType == 0) && (nuggetFormatType.dirty || nuggetFormatType.touched)"
                                    class="text-danger">
                                    Format type is required.
                                </div>

                            </div>
                        </div>
                        <div class="col-md-4 col-12 mb-3" *ngIf="manageNugget.formatType == 1">
                            <div class="custom-input-lg">
                                <label for="exampleFormControlInput1" class="form-label">Text Template</label>
                                <select name="NuggetTextType" [(ngModel)]="manageNugget.textType" class="form-select"
                                    aria-label="Default select example" [disabled]="isUpdateDisabled()">
                                    <option value="1">Paragraph</option>
                                    <option value="2">Bulleted List</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4 col-12 mb-3"
                            *ngIf="manageNugget.formatType == 2 ||manageNugget.formatType == 3 ">
                            <div class="custom-input-lg">
                                <label for="exampleFormControlInput1" class="form-label">Upload
                                    {{manageNugget.formatType == 2? 'Audio':'Video'}} File</label>
                                <div class="upload-btn-wrapper">
                                    <button class="btn"><i class="bi bi-upload"></i></button>
                                    <input type="file" name="NuggetMedia" id="avatar-input-file"
                                        [accept]="manageNugget.formatType == 2 ? 'audio/mpeg': 'video/mp4'"
                                        #fileMediaInput (change)="onMediaChange($event)"
                                        [disabled]="isUpdateDisabled()">
                                </div>
                                <div>
                                    <audio class="mt-2" controls style="display: block; max-width: 397px; max-height: 307px;"
                                        *ngIf="manageNugget.tempMediaPath != '' && manageNugget.formatType == 2"
                                        [src]="manageNugget.tempMediaPath"></audio>
                                    <video class="upload__img" controls style="max-width: 397px; max-height: 307px;"
                                        *ngIf="manageNugget.tempMediaPath != '' && manageNugget.formatType == 3"
                                        [src]="manageNugget.tempMediaPath"></video>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3" *ngIf="manageNugget.formatType == 2 ||manageNugget.formatType == 3">
                        <div class="custom-input-lg">
                            <label for="exampleFormControlInput1" class="form-label">Transcript</label>
                        </div>
                        <div class="col-12 custom-input-lg">
                            <textarea class="form-control" style="height: 200px;" name="NuggetTranscript"
                                [(ngModel)]="manageNugget.transcript"
                                #nuggetTranscript="ngModel" [disabled]="isUpdateDisabled()"></textarea>
                        </div>
                    </div>

                    <div class="row mt-3" *ngIf="manageNugget.formatType == 1 && manageNugget.textType == 1">
                        <div class="custom-input-lg">
                            <label for="exampleFormControlInput1" class="form-label">NoK Description</label>
                        </div>
                        <div class="col-12 custom-input-lg">
                            <textarea class="form-control" style="height: 200px;" name="NuggetDescription"
                                [(ngModel)]="manageNugget.description"
                                [required]="isSubmit && manageNugget.formatType == 1 && manageNugget.textType == 1"
                                #nuggetDescription="ngModel" [disabled]="isUpdateDisabled()"></textarea>
                            <div *ngIf="isSubmit && manageNugget.formatType == 1 && manageNugget.textType == 1 && !manageNugget.description && (nuggetDescription.dirty || nuggetDescription.touched)"
                                class="text-danger">
                                Description is required
                            </div>
                        </div>
                    </div>

                    <!-- Setup description lines-->
                    <div class="row mt-1" *ngIf="manageNugget.formatType == 1 && manageNugget.textType == 2">

                        <div class="row">
                            <div class="col-md-12 col-12 mb-1 text-danger" *ngIf="formDescriptionLines.length == 0">
                                You need to provide atleast one bullet point
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-12 mb-1">
                                <label for="exampleFormControlInput1" class="form-label">NoK Template</label> &nbsp;

                            </div>
                            <div class="col-md-3 col-12 mb-1 custom-input-lg">
                                <select class="form-select" name="NuggetDescriptionLineOrderType"
                                    [(ngModel)]="parentIsOrdered" [disabled]="isUpdateDisabled()">
                                    <option value="true">Ordered</option>
                                    <option value="false">Unordered</option>
                                </select>
                            </div>
                            <div class="col-md-3 col-12 mb-1">
                                <button id="createNok_AddDescriptionLine" class="btn btn-blue mt-1 float-right" [disabled]="isUpdateDisabled()"
                                    (click)="addDescriptionLine()">Add Line
                                </button>
                            </div>

                        </div>
                        <!--Add Ordered-->
                        <div class="container">
                            <div class="row" *ngFor="let descLine of formDescriptionLines; let i = index">
                                <div class="col-12 mb-3">
                                    <div class="custom-input-lg">
                                        <label class="form-label">Line {{i+1}}</label>
                                        <div *ngIf="isSubmit && manageNugget.formatType == 1 && manageNugget.textType == 2 && !descLine.description && (nuggetDescriptionLine.dirty || nuggetDescriptionLine.touched)"
                                            class="text-danger">
                                            Line can not be empty.
                                        </div>
                                        <div class="position-relative pe-5">
                                            <!--Parent Lines-->

                                            <!-- <input type="text" class="form-control"
                                                [name]="'NuggetDscriptonLine'+descLine.nuggetDescriptionLineId"
                                                [(ngModel)]="descLine.description" #nuggetDescriptionLine="ngModel"
                                                [required]="isSubmit && manageNugget.formatType == 1 && manageNugget.textType == 2"
                                                [disabled]="isUpdateDisabled()"> -->
                                            <textarea class="form-control" [name]="'NuggetDscriptonLine'+descLine.nuggetDescriptionLineId"
                                                [(ngModel)]="descLine.description" #nuggetDescriptionLine="ngModel"
                                                [required]="isSubmit && manageNugget.formatType == 1 && manageNugget.textType == 2" [disabled]="isUpdateDisabled()"
                                                rows="2"> </textarea>

                                            <button id="createNok_RemoveDescriptionLine" (click)="removeDescriptionLine(descLine)"
                                                [disabled]="isUpdateDisabled()" class="delete-ordered-list-btn"><i
                                                    class="bi bi-trash3"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="sub_list">
                                    <div class="col-12 mb-3 ps-5 "
                                        *ngFor="let childLine of descLine.childLines; let cIndex = index">
                                        <div *ngIf="cIndex==0" class=" mb-3 position-relative">
                                            <div class="col-4 sub_list-line">
                                                <!-- <label class="form-label">Sublist Type:</label>
                                                <select class="form-select"
                                                    [name]="'NuggetDscriptonType'+descLine.nuggetDescriptionLineId"
                                                    [(ngModel)]="descLine.isOrdered" [disabled]="isUpdateDisabled()">
                                                    <option value="true">Ordered</option>
                                                    <option value="false">Unordered</option>
                                                </select> -->
                                            </div>
                                        </div>

                                        <div class="custom-input-lg">
                                            <div class="position-relative pe-5 sub_list-line">
                                                <!--Child Lines-->
                                                <!-- <input type="text" class="form-control"
                                                    [name]="'NuggetchildLine'+childLine.nuggetDescriptionLineId"
                                                    [(ngModel)]="childLine.description" [disabled]="isUpdateDisabled()"> -->
                                                <textarea class="form-control" [name]="'NuggetchildLine'+childLine.nuggetDescriptionLineId"
                                                    [(ngModel)]="childLine.description" [disabled]="isUpdateDisabled()" rows="2">
                                                                                                    </textarea>

                                                <button id="createNok_RemoveChildDescriptionLine" [disabled]="isUpdateDisabled()"
                                                    (click)="removeChildLine(descLine, childLine)"
                                                    class="delete-ordered-list-btn"><i
                                                        class="bi bi-trash3"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-end pe-5"> <a id="createNok_AddChildDescriptionLine" class="add_sub_link cursor-pointer"
                                        *ngIf="!isUpdateDisabled()" (click)="addChildLine(descLine)">Add Sub Line</a>
                                </div>
                            </div>

                        </div>
                        <!--Add Ordered-END-->

                        <!-- <div class="row" *ngFor="let descLine of formDescriptionLines">
                            <div class="row">
                                <div class="col-md-7 col-12 mb-1">
                                    <input type="text" class="form-control"
                                        [name]="'NuggetDscriptonLine'+descLine.nuggetDescriptionLineId"
                                        [(ngModel)]="descLine.description" #nuggetDescriptionLine="ngModel"
                                        [required]="isSubmit && manageNugget.formatType == 1 && manageNugget.textType == 2" [disabled]="isUpdateDisabled()">
                                    &nbsp;
                                    <div *ngIf="isSubmit && manageNugget.formatType == 1 && manageNugget.textType == 2 && !descLine.description && (nuggetDescriptionLine.dirty || nuggetDescriptionLine.touched)"
                                        class="text-danger">
                                        Line can not be empty.
                                    </div>
                                </div>
                                <div class="col-md-2 col-12 mb-1">
                                    <select class="form-select"
                                        [name]="'NuggetDscriptonType'+descLine.nuggetDescriptionLineId"
                                        [(ngModel)]="descLine.isOrdered" [disabled]="isUpdateDisabled()">
                                        <option value="true">Ordered</option>
                                        <option value="false">Unordered</option>
                                    </select>
                                </div>
                                <div class="col-md-1 col-12">
                                    <button class="btn btn-blue mt-1 float-right"
                                        (click)="removeDescriptionLine(descLine)" [disabled]="isUpdateDisabled()">
                                        X
                                    </button>
                                </div>
                                <div class="col-md-2 col-12">
                                    <button class="btn btn-blue mt-1 float-right" [disabled]="isUpdateDisabled()" (click)="addChildLine(descLine)">
                                        Add Sub Line
                                    </button>
                                </div>
                            </div>

                            <div class="row" *ngFor="let childLine of descLine.childLines">
                                <div class="col-md-10 col-12 mb-1">
                                    <input type="text" class="form-control"
                                        [name]="'NuggetchildLine'+childLine.nuggetDescriptionLineId"
                                        [(ngModel)]="childLine.description" [disabled]="isUpdateDisabled()"> &nbsp;
                                </div>



                                <div class="col-md-2 col-12">
                                    <button class="btn btn-blue mt-1 float-right"
                                    [disabled]="isUpdateDisabled()" (click)="removeChildLine(descLine, childLine)">
                                        X
                                    </button>
                                </div>
                            </div>
                            <hr>
                        </div> -->
                    </div>
                </form>
                <div class="hr my-4"></div>
                <div class="row">
                    <div class=" col-6">
                        <button id="createNok_Step1Back" (click)="openStep(1)" class="btn btn-blue btn-outline-blue mt-2"><i
                                class="bi bi-arrow-left pe-1"></i>
                            Back</button>
                    </div>
                    <div class="col-6 text-end">
                        <button id="createNok_Step2Next" (click)="validateStep(step2Form, 2, true)" class="btn btn-blue mt-2">Next<i
                                class="bi bi-arrow-right ps-1"></i></button>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-md-3 col-12">
                        
                    </div>
                    
                    
                    <div class="col-12 col-md-9 text-md-end">
                        <button class="btn btn-blue  mt-2"
                            (click)="saveDraft(step1Form, step2Form,step3Form,step4Form)"><i
                                class="bi bi-journals pe-1 "></i> Save
                            as Draft</button>&nbsp;
                        <button class="btn btn-green mt-2"
                            (click)="submitNok(step1Form,step2Form,step3Form,step4Form)"><i
                                class="bi bi-check-circle pe-1"></i> Save &
                            Submit</button>&nbsp;
                    </div>
                </div> -->
            </div>
            <!--Step 3 starts-->

            <div class="tab-pane" id="home-tab-pane" [class]="currentStep === 3 ? 'fade active show':''">
                <form #step3Form="ngForm">

                    <div class="accordion accordion__custom " id="accordionStep1">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <span class="circle_number">1</span>
                                    HAZARD
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div id="section-hazard-type" class="row">
                                        <div class="col-md-12 col-12 mt-3">

                                            <h5 class="font-size-16 fw-bold">Hazard Type 
                                                <span class="text-danger">*</span>
                                                
                                                &nbsp; 
                                                <input
                                                    class="form-check-input" type="checkbox" id="select-all-hazardTypes" (change)="selectAllHazardTypes($event)"
                                                    [checked]="areAllHazardTypeSelected()">
                                                <label for="select-all-hazardTypes">
                                                    Select All
                                                </label>

                                            </h5>
                                            <ng-select [items]="allHazardTypes" name="NuggetHazardTypes"
                                                [multiple]="true" bindLabel="name" [closeOnSelect]="false"
                                                bindValue="hazardTypeId" [(ngModel)]="selectedHazardTypeIds"
                                                [required]="isSubmit" (change)="onHazardTypeChange()"
                                                #nuggetHazardTypes="ngModel" [disabled]="isUpdateDisabled()">
                                                <ng-template ng-option-tmp let-item="item" let-item$="item$"
                                                    let-index="index">
                                                    <input class="form-check-input"
                                                        [name]="'NuggetHazardType_'+item.hazardTypeId"
                                                        id="item-{{index}}" type="checkbox"
                                                        [ngModel]="item$.selected" />
                                                    {{item.name}}
                                                </ng-template>
                                            </ng-select>

                                            <div *ngIf="isSubmit && !selectedHazardTypeIds?.length && (nuggetHazardTypes.dirty || nuggetHazardTypes.touched)"
                                                class="text-danger">
                                                Hazard Type is required.
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row" id="section-hazard-cluster"
                                        *ngIf="filteredHazardClusters.length > 0">
                                        <div class="col-md-12 col-12 mt-3">

                                            <h5 class="font-size-16 fw-bold">Hazard Cluster 
                                                <span class="text-danger">*</span>
                                            
                                                &nbsp; 
                                                <input
                                                    class="form-check-input" type="checkbox" id="select-all-hazardClusters" (change)="selectAllHazardClusters($event)"
                                                    [checked]="areAllHazardClustersSelected()">
                                                <label for="select-all-hazardClusters">
                                                    Select All
                                                </label>
                                            </h5>
                                            <ng-select name="NuggetHazardClusters" [items]="filteredHazardClusters"
                                                [multiple]="true" bindLabel="name" [closeOnSelect]="false"
                                                bindValue="hazardClusterId" [(ngModel)]="selectedHazardClusterIds"
                                                (change)="onHazardClusterChange()" [required]="isSubmit"
                                                #nuggetHazardClusters="ngModel" [disabled]="isUpdateDisabled()">
                                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                    <span class="ng-value-label">{{item.name}}
                                                        <b>({{item.hazardTypeName}})</b></span>
                                                    <span id="createNok_HazardCluster" class="ng-value-icon right" (click)="clear(item)"
                                                        aria-hidden="true">×</span>
                                                </ng-template>

                                                <ng-template ng-option-tmp let-item="item" let-item$="item$"
                                                    let-index="index">
                                                    <input class="form-check-input"
                                                        [name]="'NuggetHazardCluster_'+item.hazardClusterId"
                                                        id="item-{{index}}" type="checkbox"
                                                        [ngModel]="item$.selected" />
                                                    {{item.name }} <b>({{item.hazardTypeName}})</b>
                                                </ng-template>
                                            </ng-select>
                                            <div *ngIf="isSubmit && !selectedHazardClusterIds?.length && (nuggetHazardClusters.dirty || nuggetHazardClusters.touched)"
                                                class="text-danger">
                                                Hazard Cluster is required.
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" id="section-hazard-cluster"
                                        *ngIf="filteredSpecificHazard.length > 0">
                                        <div class="col-md-12 col-12 mt-3">

                                            <h5 class="font-size-16 fw-bold">Specific Hazard <span class="text-danger">*</span>
                                                
                                                &nbsp; 
                                                <input
                                                    class="form-check-input" type="checkbox" id="select-all-hazardvalues" (change)="selectAllHazards($event)"
                                                    [checked]="areAllHazardsSelected()">
                                                <label for="select-all-hazardvalues">
                                                    Select All
                                                </label>
                                            </h5>
                                            <div *ngIf="isSubmit && !atleastOneSpecificHazard()" class="text-danger">
                                                Please select at least one specific Hazard.</div>
                                            <div class="hr pt-2"></div>
                                            <div class="row"
                                                *ngFor="let group of filteredSpecificHazard | groupBy: 'hazardClusterName'">
                                                <h5 class="font-size-14 fw-bold mt-4">{{ group[0] }}</h5>
                                                <div *ngFor="let specificHazard of group[1]" class="col col-md-6 d-flex mt-3 align-items-start">
                                                    <input class="form-check-input"
                                                        [checked]="isSpecificHazardSelected(specificHazard.hazardValueId)"
                                                        [name]="'NuggetSpecificHazard_'+specificHazard.hazardValueId"
                                                        [id]="'chk_spec_hazard_id_'+specificHazard.hazardValueId"
                                                        type="checkbox" [value]="specificHazard.hazardValueId"
                                                        style="width: 1.5em; height: 1.5em;"
                                                        (change)="toggleHazardSelection(specificHazard)"
                                                        [disabled]="isUpdateDisabled()">
                                                    <label style="margin-left: 1px;" class="form-check-label"
                                                        [for]="'chk_spec_hazard_id_'+specificHazard.hazardValueId">
                                                        {{ specificHazard.name }}
                                                    </label>
                                                </div>
                                                <div class="hr py-2"></div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                    <span class="circle_number">2</span>
                                    IHR Technical Areas
                                </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div id="section-ihr-technical-area" class="row">
                                        <div class="col-md-12 col-12 mt-3">

                                            <h5 class="font-size-16 fw-bold"> Pillar 
                                                <span class="text-danger">*</span>

                                                &nbsp; 
                                                <input
                                                    class="form-check-input" type="checkbox" id="select-all-pillars" (change)="selectAllPillars($event)"
                                                    [checked]="areAllPillarsSelected()">
                                                <label for="select-all-pillars">
                                                    Select All
                                                </label>
                                            </h5>
                                            <ng-select name="NuggetIHRTechnicalArea" [items]="allIHRTechnicalArea"
                                                [multiple]="true" bindLabel="name" [closeOnSelect]="false"
                                                bindValue="ihrTechnicalAreaId" [(ngModel)]="selectedIHRTechnicalAreaIds"
                                                (change)="onIHRTechnicalAreaChange()" [required]="isSubmit"
                                                #nuggetIHRTechArea="ngModel" [disabled]="isUpdateDisabled()">
                                                <ng-template ng-option-tmp let-item="item" let-item$="item$"
                                                    let-index="index">
                                                    <input class="form-check-input"
                                                        [name]="'NuggetIHRTechnicalArea_'+item.ihrTechnicalAreaId"
                                                        id="item-{{index}}" type="checkbox"
                                                        [ngModel]="item$.selected" />
                                                    {{item.name}}
                                                </ng-template>
                                            </ng-select>

                                            <div *ngIf="isSubmit && !selectedIHRTechnicalAreaIds?.length && (nuggetHazardTypes.dirty || nuggetHazardTypes.touched)"
                                                class="text-danger">
                                                Pillar is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div id="section-ihr-level" *ngIf="filteredIHRLevels.length > 0" class="row">
                                        <div class="col-md-12 col-12 mt-3">

                                            <h5 class="font-size-16 fw-bold mt-3"> IHR Technical Area 
                                                <span class="text-danger">*</span>
                                            
                                                &nbsp; 
                                                <input
                                                    class="form-check-input" type="checkbox" id="select-all-techareas" (change)="selectAllTechAreas($event)"
                                                    [checked]="areAllTechAreasSelected()">
                                                <label for="select-all-techareas">
                                                    Select All
                                                </label>
                                            </h5>
                                            <ng-select name="NuggetIHRLevels" [items]="filteredIHRLevels"
                                                [multiple]="true" bindLabel="name" [closeOnSelect]="false"
                                                bindValue="ihrLevelId" [(ngModel)]="selectedIHRLevelIds"
                                                (change)="onIHRLevelChange()" [required]="isSubmit"
                                                #nuggetIHRLevel="ngModel" [disabled]="isUpdateDisabled()">
                                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                    <span class="ng-value-label">{{item.name}}
                                                        <b>({{item.ihrTechnicalAreaName}})</b></span>
                                                    <span id="createNok_IhrLevels" class="ng-value-icon right" (click)="clear(item)"
                                                        aria-hidden="true">×</span>
                                                </ng-template>

                                                <ng-template ng-option-tmp let-item="item" let-item$="item$"
                                                    let-index="index">
                                                    <input class="form-check-input"
                                                        [name]="'NuggetIHRLevel_'+item.ihrLevelId" id="item-{{index}}"
                                                        type="checkbox" [ngModel]="item$.selected" />
                                                    {{item.name }} <b>({{item.ihrTechnicalAreaName}})</b>
                                                </ng-template>
                                            </ng-select>
                                            <div *ngIf="isSubmit && !selectedIHRLevelIds?.length && (nuggetIHRLevel.dirty || nuggetIHRLevel.touched)"
                                                class="text-danger">
                                                IHR Techical area is required.
                                            </div>
                                        </div>
                                    </div>

                                    <div id="section-specific-ihrlevel" *ngIf="filteredSpecificIHRLevels.length > 0">
                                        <h5 class="font-size-16 fw-bold mt-3"> Indicators 
                                            <span class="text-danger">*</span>
                                            &nbsp; 
                                            <input
                                                class="form-check-input" type="checkbox" id="select-all-indicators" (change)="selectAllIndicators($event)"
                                                [checked]="areAllIndicatorsSelected()">
                                            <label for="select-all-indicators">
                                                Select All
                                            </label>
                                        </h5>
                                        <div *ngIf="isSubmit && !atleastOneSpecificIHRLevel()" class="text-danger">
                                            Please select at least one indicator.</div>

                                        <div class="hr pt-2"></div>
                                        <div class="row"
                                            *ngFor="let group of filteredSpecificIHRLevels | groupBy: 'ihrLevelName'">
                                            <h5 class="font-size-14 fw-bold mt-4">{{ group[0] }}</h5>
                                            <div *ngFor="let specificLevel of group[1]" class="col col-md-6 mt-3 d-flex align-items-start">
                                                <input class="form-check-input"
                                                    [id]="'chk_spec_ihrlevel_id_'+specificLevel.ihrSpecificLevelId"
                                                    [name]="'NuggetIHRSpecificLevel_'+specificLevel.ihrSpecificLevelId"
                                                    [checked]="isSpecificIhrLevelSelected(specificLevel.ihrSpecificLevelId)"
                                                    type="checkbox" [value]="specificLevel.ihrSpecificLevelId"
                                                    [disabled]="isUpdateDisabled()"
                                                    (change)="toggleIHRSpecificLevelSelection(specificLevel)">
                                                <label style="margin-left: 1px;" class="form-check-label"
                                                    [for]="'chk_spec_ihrlevel_id_'+specificLevel.ihrSpecificLevelId">
                                                    {{ specificLevel.name }}
                                                </label>
                                            </div>
                                            <div class="hr py-2"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <span class="circle_number">3</span>
                                    WHO BENCHMARK FOR IHR CAPACITIES
                                </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div id="section-ihr-capacity-area" class="row">
                                        <div class="col-md-12 col-12 mt-3">
                                            <h5 class="font-size-16 fw-bold"> WHO Benchmarks 
                                                <span class="text-danger">*</span>
                                                
                                                &nbsp; 
                                                <input
                                                    class="form-check-input" type="checkbox" id="select-all-whobenchmarks" (change)="selectAllBenchmarks($event)"
                                                    [checked]="areAllBenchmarksSelected()">
                                                <label for="select-all-whobenchmarks">
                                                    Select All
                                                </label>
                                            </h5>
                                            <ng-select name="NuggetIHRCapacities" [items]="allIhrCapacities"
                                                [multiple]="true" bindLabel="name" [closeOnSelect]="false"
                                                bindValue="ihrCapacityId" [(ngModel)]="selectedIHRCapacityIds"
                                                (change)="onIHRCapacityChange()" [required]="isSubmit"
                                                #nuggetWHOBenchMark="ngModel" [disabled]="isUpdateDisabled()">
                                                <ng-template ng-option-tmp let-item="item" let-item$="item$"
                                                    let-index="index">
                                                    <input class="form-check-input"
                                                        [name]="'NuggetWHOBenchMarks_'+item.ihrCapacityId"
                                                        id="item-{{index}}" type="checkbox"
                                                        [ngModel]="item$.selected" />
                                                    {{item.name}}
                                                </ng-template>
                                            </ng-select>
                                            <div *ngIf="isSubmit && !selectedIHRCapacityIds?.length && (nuggetWHOBenchMark.dirty || nuggetWHOBenchMark.touched)"
                                                class="text-danger">
                                                WHO benchmark is required.
                                            </div>
                                        </div>
                                    </div>

                                    <div id="section-specific-ihrcapacity"
                                        *ngIf="filteredIhrSpecificCapacities.length > 0">
                                        <h5 class="font-size-16 fw-bold mt-3"> IHR Capacties
                                            <span class="text-danger">*</span>
                                        
                                            &nbsp; 
                                            <input
                                                class="form-check-input" type="checkbox" id="select-all-ihrcapacities" (change)="selectAllIhrcapacities($event)"
                                                [checked]="areAllIhrcapacitiesSelected()">
                                            <label for="select-all-ihrcapacities">
                                                Select All
                                            </label>
                                        </h5>
                                        <div *ngIf="isSubmit && !atleastOneSpecificIHRCapacity()" class="text-danger">
                                            Please select at least one capacity.</div>

                                        <div class="hr pt-2"></div>
                                        <div class="row"
                                            *ngFor="let group of filteredIhrSpecificCapacities | groupBy: 'ihrCapacityName'">
                                            <h5 class="font-size-14 fw-bold mt-4">{{ group[0] }}</h5>
                                            <div *ngFor="let specificCapacity of group[1]" class="col col-md-6 d-flex align-items-start mt-3">
                                                <input class="form-check-input"
                                                    [name]="'NuggetIHRSpecificCapacity_'+specificCapacity.ihrSpecificCapacityId"
                                                    [id]="'chk_spec_ihrcapacity_id_'+specificCapacity.ihrSpecificCapacityId"
                                                    [checked]="isSpecificIhrCapacitySelected(specificCapacity.ihrSpecificCapacityId)"
                                                    type="checkbox" [value]="specificCapacity.ihrSpecificCapacityId"
                                                    [disabled]="isUpdateDisabled()"
                                                    (change)="toggleIHRSpecificCapacitySelection(specificCapacity)">
                                                <label style="margin-left: 4px; cursor: pointer;"
                                                    class="form-check-label"
                                                    [for]="'chk_spec_ihrcapacity_id_'+specificCapacity.ihrSpecificCapacityId">
                                                    {{ specificCapacity.name }}
                                                </label>

                                            </div>
                                            <div class="hr py-2"></div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hr my-4"></div>
                    <div class="row">
                        <div class=" col-6">
                            <button id="createNok_Step2Back" (click)="openStep(2)" class="btn btn-blue btn-outline-blue mt-2"><i
                                    class="bi bi-arrow-left pe-1"></i>
                                Back</button>
                        </div>
                        <div class="col-12 text-end">

                            <button id="createNok_Step4Next" (click)="openStep(4)" class="btn btn-blue mt-1">Next<i
                                    class="bi bi-arrow-right ps-1"></i></button>

                        </div>
                    </div>
                </form>
            </div>






            <!--Step 4 starts-->

            <div class="tab-pane fade" id="author-tab-pane" [class]="currentStep === 4 ? 'fade active show':''">
                <form #step4Form="ngForm">

                    <div class="row">

                        <div class="col-md-12 col-12 mb-3">
                            <div class="custom-input-lg">
                                <label for="txtAuthorReferenceLink" class="form-label">Reference Link</label>
                                <input type="text" id="txtAuthorReferenceLink" name="AuthorRefLink"
                                    [(ngModel)]="manageNugget.referenceLink" class="form-control"
                                    placeholder="Reference Link"
                                    pattern="^(https?:\/\/)?[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,}(\/\S*)?$"
                                    #nuggetAuthorRefLink="ngModel" [disabled]="isUpdateDisabled()">
                                <div *ngIf="isSubmit && nuggetAuthorRefLink.errors && nuggetAuthorRefLink.errors['pattern']"
                                    class="text-danger">
                                    Please enter a valid URL
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-md-12 col-12 mb-3 text-right">
                            <button class="btn btn-blue mt-2" (click)="addAuthor()">Add Author</button>
                        </div> -->

                    <div class="hr position-relative mt-4 mb-4">
                        <!-- <i class="bi bi-plus-circle-fill icon-add_circle " (click)="addAuthor()" data-bs-toggle="tooltip" title=""
                            data-bs-original-title="Add Author" aria-label="Add Author"></i> -->

                        <div class="author-icon-inner">
                            <button id="createNok_AddAuthor" class="author-btn" [disabled]="isUpdateDisabled()" (click)="addAuthor()"><i
                                    class="bi bi-plus"></i><span>Add
                                    Author</span></button>
                        </div>

                    </div>

                    <div class="col-md-12 col-12 mb-3 text-right text-danger" *ngIf="isSubmit && !atleastOneAuthor()">
                        Please add atleast one author.
                    </div>

                    <!-- Author Loop -->
                    <div *ngFor="let author of manageNugget.nuggetAuthors ; let i = index">



                        <div class=" mt-5 mb-2">
                            <h5 class="mb-0 font-size-16 fw-bold">Author {{i + 1}}</h5>
                            <!-- <button class="btn btn-blue" style="display: inline-block;"
                                    (click)="removeAuthor(author)">Remove</button> -->
                        </div>

                        <div class="btn-delete-line">
                            <div class="row">
                                <div class="col-md-6 col-12 mb-3">
                                    <div class="custom-input-lg">
                                        <label [for]="'txtAuthorName'+author.nuggetAuthorId" class="form-label">Name  <span class="text-danger">*</span></label>
                                          
                                        <input type="text" [id]="'txtAuthorName'+author.nuggetAuthorId"
                                            [name]="'AuthorName'+author.nuggetAuthorId" [(ngModel)]="author.name"
                                            class="form-control" [required]="isSubmit" #nuggetAuthorName="ngModel"
                                            placeholder="Author Name" [disabled]="isUpdateDisabled()">
                                        <div *ngIf="isSubmit && !author.name && (nuggetAuthorName.dirty || nuggetAuthorName.touched)"
                                            class="text-danger">
                                            Name is required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-12 mb-3">
                                    <div class="custom-input-lg">
                                        <label [for]="'txtAuthorTitle'+author.nuggetAuthorId" class="form-label">Author
                                            Title</label>
                                        <input type="text" [id]="'txtAuthorTitle'+author.nuggetAuthorId"
                                            [name]="'AuthorTitle'+author.nuggetAuthorId" [(ngModel)]="author.title"
                                            class="form-control" placeholder="Author Title" [required]="isSubmit"
                                            #nuggetAuthorTitle="ngModel" [disabled]="isUpdateDisabled()">
                                        <div *ngIf="isSubmit && !author.title && (nuggetAuthorTitle.dirty || nuggetAuthorTitle.touched)"
                                            class="text-danger">
                                            Title is required
                                        </div>
                                    </div>
                                    <button id="createNok_RemoveAuthor" class="btn-delete" [disabled]="isUpdateDisabled()"
                                        (click)="removeAuthor(author)"><i class="bi bi-trash3"></i></button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 col-12 mb-3">
                                    <div class="custom-input-lg">
                                        <label [for]="'ddlAuthorCountry'+author.nuggetAuthorId"
                                            class="form-label">Author
                                            Country</label>
                                        <select [id]="'ddlAuthorCountry'+author.nuggetAuthorId" class="form-select"
                                            [name]="'AuhtorCountry'+author.nuggetAuthorId"
                                            [(ngModel)]="author.countryId" [required]="isSubmit"
                                            #nuggetAuthorCountryId="ngModel" [disabled]="isUpdateDisabled()">
                                            <option *ngFor="let country of allCountriesSorted" [value]="country.countryId">
                                                {{ country.name }}
                                            </option>
                                        </select>
                                        <div *ngIf="isSubmit && (!author.countryId || author.countryId == 0) && (nuggetAuthorCountryId.dirty || nuggetAuthorCountryId.touched)"
                                            class="text-danger">
                                            Country is required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12 mb-3">
                                    <div class="custom-input-lg">
                                        <label [for]="'txtAuthorYear'+author.nuggetAuthorId"
                                            class="form-label">Year</label>
                                        <input type="number" [id]="'txtAuthorYear'+author.nuggetAuthorId"
                                            [name]="'AuthorYear'+author.nuggetAuthorId" [(ngModel)]="author.year"
                                            class="form-control" placeholder="Year"
                                            [required]="isSubmit && !author.date" pattern="[0-9]{4}"
                                            #nuggetAuthorYear="ngModel" [disabled]="isUpdateDisabled()">
                                        <div *ngIf="isSubmit && !author.date && !author.year && (nuggetAuthorYear.dirty || nuggetAuthorYear.touched)"
                                            class="text-danger">
                                            Year is required
                                        </div>
                                        <div *ngIf="isSubmit && nuggetAuthorYear.errors && nuggetAuthorYear.errors['pattern']"
                                            class="text-danger">
                                            Year must be 4 characters without spaces
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12 mb-3">
                                    <div class="custom-input-lg position-relative">
                                        <label [for]="'txtAuthorDate'+author.nuggetAuthorId"
                                            class="form-label">Date</label>
                                        <input type="text" [id]="'txtAuthorDate'+author.nuggetAuthorId"
                                            [name]="'AuthorDate'+author.nuggetAuthorId" [(ngModel)]="author.date"
                                            class="form-control" placeholder="Date"
                                            [required]="isSubmit && !author.year" #nuggetAuthorDate="ngModel"
                                            placeholder="Select Date" bsDatepicker [disabled]="isUpdateDisabled()">

                                        <div *ngIf="isSubmit && !author.year && !author.date && (nuggetAuthorDate.dirty || nuggetAuthorDate.touched)"
                                            class="text-danger">
                                            Date is required
                                        </div>
                                        <i class="bi bi-calendar4-week calendar-icon"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
                <div class="hr my-4"></div>
                <div class="row">
                    <div class=" col-3">
                        <button id="createNok_Step3Back" (click)="openStep(3)" class="btn btn-blue btn-outline-blue mt-2"><i
                                class="bi bi-arrow-left pe-1"></i>
                            Back</button>
                    </div>
                    <div class="col-9 text-md-end">

                        <button id="createNok_CheckIn" *ngIf="manageNugget.status == 2 && hasPermission('CheckInNoKAction', loggedInUser.role?.role)" class="btn btn-blue  mt-2" (click)="checkInNugget()"><i
                                class="bi bi-bookmark-check pe-1 "></i> Check In </button>&nbsp;

                        <button id="createNok_OpenReturn" *ngIf="manageNugget.status == 3 && hasPermission('ReturnNoKAction', loggedInUser.role?.role)" class="btn btn-red  mt-2"
                            (click)="openReturnModal()"><i class="bi bi-arrow-return-left pe-1 "></i> Return
                        </button>&nbsp;

                        <button id="createNok_OpenReject" *ngIf="manageNugget.status == 3 && hasPermission('RejectNoKAction', loggedInUser.role?.role)" class="btn btn-red  mt-2"
                            (click)="openRejectModal()"><i class="bi bi-x-square pe-1 "></i> Reject </button>&nbsp;

                        <button id="createNok_Publish" *ngIf="isPublishableNugget()" class="btn btn-green mt-2" (click)="publishNugget()"><i
                                class="bi bi-globe pe-1"></i> Publish </button>&nbsp;

                        <button id="createNok_Preview" *ngIf="manageNugget.nuggetId > 0" class="btn btn-gray mt-2" (click)="gotoPreview()"><i class="bi bi-eye pe-1"></i>
                            Preview </button>&nbsp;

                        <button id="createNok_SaveDraft" *ngIf="isSaveButtonEnabled()" class="btn btn-blue  mt-2"
                            (click)="saveDraft(step1Form, step2Form,step3Form,step4Form)"><i
                                class="bi bi-journals pe-1"></i>{{manageNugget.status == 1? 'Save as Draft': 'Save'}}</button>&nbsp;

                        <button id="createNok_SubmitNok" *ngIf="manageNugget.status == 1 || manageNugget.status == 4" class="btn btn-green mt-2"
                            (click)="submitNok(step1Form,step2Form,step3Form,step4Form)"><i
                                class="bi bi-check-circle pe-1"></i>
                                {{manageNugget.status == 1 ? 'Save & Submit': 'Save & Return for Review'}}
                        </button>&nbsp;
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--wizard-END-->
</div>

<!--Return/Reject Modal Start-->
<div class="custom-modal-sm" *ngIf="modal.show">
    <div class="custom-modal-sm-content">
        <div class="text-end"><i id="createNok_CloseRModal" (click)="closeModal()" class="bi bi-x-lg cursor-pointer font-size-16"></i></div>
        <h5>{{modal.title}}</h5>
        <div class="row">
            <div class="col-12">
                {{modal.message}}
                <textarea name="RejectionReason" [(ngModel)]="modal.reason" style="min-height: 100px; resize: none;"
                    class="form-control"></textarea>
            </div>
            <div class="col-12 text-end">
                <button id="createNok_CloseRModal2" [disabled]="modal.disableButtons" class="btn btn-gray  mt-2" (click)="closeModal()"><i
                        class="bi bi-x-circle"></i>
                    Cancel</button> &nbsp;
                <button id="createNok_SubmitRModal" [disabled]="modal.disableButtons" (click)="returnRejectNugget()" class="btn btn-red  mt-2">
                    <i class="bi bi-check-circle"></i>
                    {{modal.buttonTitle}}</button>
            </div>
        </div>
    </div>
</div>
<!--Return/Reject Modal End-->

<ng-template #uploadModal >
    <div class="modal-content" >
    <div class="modal-body">
        <div class="custom-modal-sm-content">
            <div class="text-end"><i id="createNok_CloseCropperModal" class="bi bi-x-lg cursor-pointer font-size-22" data-bs-dismiss="modal" aria-label="Close" (click)="closeImageModal()"></i></div>
            <div class="text-center my-4">
                <image-cropper
                [cropperMinWidth] = "750"
                [cropperMinHeight] = "500"
                [cropperMaxWidth] = "1500"
                [cropperMaxHeight] = "1000"
                [imageChangedEvent]="imageChangedEvent"
                [aspectRatio]="3 / 2"
                imageAltText="Alternative image text"
                output="base64"
                format="png"
                (imageCropped)="imageCropped($event)"
                ></image-cropper>
            </div>
            <div class="text-end">
                <a id="createNok_CloseCropperModal2" class="text-black pe-2" (click)="closeImageModal()">Cancel</a> 
                <button id="createNok_SubmitCropperModal" class="btn btn-blue" (click)="continueImageModal()"> Save </button>
            </div>
        </div>
    </div>
    </div>
</ng-template>

