import { Component, ElementRef, OnInit, SecurityContext, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NokService } from '../core/services/nok/nok.service';
import { AutoSuggestResponse, Continent, Country, GetCategoryModel, GetCategoryResponse, HazardCluster, HazardType, HazardValue, IhrCapacity, IhrLevel, IhrSpecificCapacity, IhrSpecificLevel, IhrTechnicalArea, ManageNuggetCommand, NokSourceType, NuggetAuthor, NuggetDescriptionLine, NuggetHazardCluster, NuggetHazardType, NuggetHazardValue, NuggetIHRCapacity, NuggetIHRSpecificLevel, NuggetIHRTechnicalArea, NuggetRegion, NuggetStatus, PersonType, Region, ReturnRejectReviewerModal, UpdateStatusCommand } from '../core/models/category/get-category.model';
import { Form, FormsModule, NgForm, NgModel, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import * as _ from 'lodash';
import { BsDatepickerModule, } from 'ngx-bootstrap/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GroupByPipe } from '../core/pipes/group-by.pipe';
import { Observable, Subject, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, retry, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../core/services/loader/loader.service';
import { LoaderComponent } from '../shared/components/loader/loader.component';
import { CommonHttpService } from '../core/services/common-http/common-http.service';
import { userAccess } from '../core/constant/userAccess.constant';
import { LoginService } from '../core/services/login/login.service';
import { LoggedInUser } from '../core/models/user/loggedInUser.model';
import { Dimensions, ImageCroppedEvent, ImageCropperComponent, base64ToFile} from 'ngx-image-cropper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';



@Component({
  selector: 'app-create-nok',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgSelectModule, BsDatepickerModule, GroupByPipe, ImageCropperComponent, LoaderComponent],
  templateUrl: './create-nok.component.html',
  styleUrls: ['./create-nok.component.scss']
})

export class CreateNokComponent implements OnInit {
  // Simulating some asynchronous task


  keywordSuggestions$: Observable<any[]> = of([]);
  tagsSuggestions$: Observable<any[]> = of([]);
  keyWordsearch$ = new Subject<string>();
  tagSearch$ = new Subject<string>();

  queryNuggetId: number = 0;
  thumbnailFile: File | undefined;
  mediaFile: File | undefined;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  croppedImageBase64: any;
  
  baseImageUrl: string = `${environment.serverUrl}/User/GetUserImage/`
  parentIsOrdered: boolean = false;
  manageNugget: ManageNuggetCommand = this.initializeManageNugget();

  // Hazard Fields.
  allHazardTypes: HazardType[] = [];

  allHazardClusters: HazardCluster[] = [];
  filteredHazardClusters: HazardCluster[] = [];

  allSpecificHazard: HazardValue[] = [];
  filteredSpecificHazard: HazardValue[] = [];

  selectedSpecificHazardIds: number[] = [];
  selectedHazardClusterIds: number[] = [];
  selectedHazardTypeIds: number[] = [];

  // IHR Technical Area Fields.
  allIHRTechnicalArea: IhrTechnicalArea[] = [];
  allIHRLevels: IhrLevel[] = [];
  filteredIHRLevels: IhrLevel[] = [];

  allSpecificIHRLevels: IhrSpecificLevel[] = [];
  filteredSpecificIHRLevels: IhrSpecificLevel[] = [];

  selectedSpecificIHRLevelIds: number[] = [];
  selectedIHRLevelIds: number[] = [];
  selectedIHRTechnicalAreaIds: number[] = [];

  // WHO Benchmarks and Capacity Fields.
  allIhrCapacities: IhrCapacity[] = [];
  allIhrSpecificCapacities: IhrSpecificCapacity[] = [];
  filteredIhrSpecificCapacities: IhrSpecificCapacity[] = [];

  selectedIHRCapacityIds: number[] = [];
  selectedSpecificIHRCapacityIds: number[] = [];



  // region, country, continent
  allContinents: Continent[] = [];
  allRegions: Region[] = [];
  filteredRegions: Region[] = [];
  allCountries: Country[] = [];
  allCountriesSorted: Country[] = [];
  filteredCountries: Country[] = [];
  selectedRegionIds: number[] = [];
  selectedCountryIds: number[] = [];

  //Person, NOK Source.
  allPersonTypes: PersonType[] = [];
  allNokSourceTypes: NokSourceType[] = [];
  selectedPersonIds: number[] = [];
  selectedNokSourceIds: number[] = [];

  //start date, end date.
  startDate: Date;
  endDate: Date;

  formDescriptionLines: NuggetDescriptionLine[] = [];

  currentStep: Number = 1;
  stepClickDisabled: boolean = true;
  categoryList: any;

  isSubmit: boolean = false;

  // Return Reject Modal Properties.
  modal: ReturnRejectReviewerModal = {
    title: "",
    show: false,
    message: "",
    buttonTitle: "",
    modalOperation: NuggetStatus.Return,
    nuggetId: 0,
    reason: "",
    disableButtons: false,
    reviewerEmails: []
  }
  loggedInUser: LoggedInUser;
  uploadModalRef: any;

  constructor(
    private nokService: NokService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private http: HttpClient,
    private router: Router,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private loaderService: LoaderService,
    private loginService: LoginService) {
  }

  hasPermission(tag: string, role: any): boolean {
    if(role == "System Admin")
      return true;

    return userAccess[role].includes(tag);
  }
  
  ngOnInit(): void {
    this.loginService.getLoginInfo().subscribe((data) => {
      this.loggedInUser = data;
    });
    this.keywordSuggestions$ = this.getSuggestions(1);
    this.tagsSuggestions$ = this.getSuggestions(2);


    this.route.params.subscribe(params => {
      if (params['nuggetId'] !== undefined) {
        this.queryNuggetId = +params['nuggetId'];
      }
    });
    this.currentStep = 1;
    this.isStepActive(this.currentStep);
    let getCategoryRequest: GetCategoryModel = {
      includeHazard: true,
      includeCapacity: true,
      includeRegion: true,
      includeNOKSourceType: true,
      includePersonType: true,
      includeTechnicalArea: true
    }
     

    // Show Loader.
    this.loaderService.show();

    this.nokService.getAllCategories(getCategoryRequest).subscribe({
      next: (res: GetCategoryResponse) => {
        this.loaderService.hide();

        this.categoryList = res;
        this.allHazardTypes = this.categoryList.hazardTypes;
        // Prepare all hazard clusters.
        this.allHazardClusters = this.allHazardTypes.flatMap((hazardType: HazardType) =>
          hazardType.hazardClusters.map(cluster => ({
            ...cluster,
            hazardTypeName: hazardType.name
          }))
        );
        // Prepare all specific HazardValues.
        this.allSpecificHazard = this.allHazardClusters.flatMap(hazardCluster =>
          hazardCluster.hazardValues.map(hazardValue => ({
            ...hazardValue,
            hazardClusterName: hazardCluster.name
          }))
        );

        // Prepare IHR Technical Area.
        this.allIHRTechnicalArea = this.categoryList.ihrTechnicalAreas;
        // Prepare all ihr levels.
        this.allIHRLevels = this.allIHRTechnicalArea.flatMap((techArea: IhrTechnicalArea) =>
          techArea.ihrLevels.map(ihrLevel => ({
            ...ihrLevel,
            ihrTechnicalAreaId: techArea.ihrTechnicalAreaId,
            ihrTechnicalAreaName: techArea.name
          })));

        // Prepare all ihr specific levels.

        this.allSpecificIHRLevels = this.allIHRLevels.flatMap(ihrLevel =>
          ihrLevel.ihrSpecificLevels.map(specificLevel => ({
            ...specificLevel,
            ihrLevelName: ihrLevel.name
          })));


        this.allIhrCapacities = this.categoryList.ihrCapacities;
        // Prepare all ihr specific capacities.
        _.forEach(this.allIhrCapacities, (ihrCapacity) => {
          this.allIhrSpecificCapacities = _.concat(ihrCapacity.ihrSpecificCapacities, this.allIhrSpecificCapacities);
        });
        this.allIhrSpecificCapacities = this.allIhrCapacities.flatMap((ihrCapacity: IhrCapacity) =>
          ihrCapacity.ihrSpecificCapacities.map(ihrLevel => ({
            ...ihrLevel,
            ihrCapacityName: ihrCapacity.name,
          })));

        //Continent, Regions, Countries
        // this.allContinents = this.categoryList.continents;
        this.allRegions = this.categoryList.regions;// this.allContinents.flatMap(continent => continent.regions);
        this.filteredRegions = this.allRegions;

        // let placeHolderRegion: Region = { continentId: 0, regionId: 0, name: "-- SELECT ALL --", countries: [] };
        // this.filteredRegions.splice(0, 0, placeHolderRegion);

        this.allCountries = this.allRegions.flatMap(region => region.countries);
        let placeHolderContinent: Continent = { continentId: 0, name: "-- SELECT CONTINENT --", regions: [] };
        this.allContinents.splice(0, 0, placeHolderContinent);
        let placeHolderCountry: Country = { regionId: 0, name: "-- SELECT COUNTRY --", countryId: 0 };
        this.allCountries.splice(0, 0, placeHolderCountry);
        this.allCountriesSorted = this.sortCountriesAlphabetically(this.allCountries);

        // NOK Source, Person Type
        this.allNokSourceTypes = this.categoryList.nokSourceTypes;
        this.allPersonTypes = this.categoryList.personTypes;

        // Is Edit Request.
        if (this.queryNuggetId > 0) {
          // Get NOK Details.
          this.loaderService.show();

          this.nokService.getNuggetById({ nuggetId: this.queryNuggetId, includeRelatedDetails: false }).subscribe({
            next: (res: ManageNuggetCommand) => {
              this.loaderService.hide();

              // Edit: Fill General Details.
              this.manageNugget.nuggetId = res.nuggetId;
              this.manageNugget.status = res.status;
              this.manageNugget.title = res.title;
              this.manageNugget.subTitle = res.subTitle;
              this.manageNugget.shortDescription = res.shortDescription;
              this.manageNugget.description = res.description;
              this.manageNugget.continentId = res.continentId;
              this.manageNugget.transcript = res.transcript;
              if (res.thumbnail) {
                this.manageNugget.tempThumbnailPath = `${environment.storageUrl}/${environment.containerName}/${environment.nuggetThumbnailPath}/${res.thumbnail}`;
              }
              if (res.media) {
                this.manageNugget.tempMediaPath = `${environment.storageUrl}/${environment.containerName}/${environment.nuggetMediaPath}/${res.media}`;
              }
              // Edit: Fill Tags and Keywords.
              this.manageNugget.nuggetTagsModel = res.nuggetTags.map(tag => tag.title);
              this.manageNugget.nuggetKeywordsModel = res.nuggetKeywords.map(key => key.title);

              // Edit: Fill Hazards.
              this.selectedHazardTypeIds = res.nuggetHazardTypes.map(haz => haz.hazardTypeId);
              this.onHazardTypeChange();
              this.selectedHazardClusterIds = res.nuggetHazardTypes.flatMap(item =>
                item.nuggetHazardClusters.map(cluster => cluster.hazardClusterId)
              );
              this.onHazardClusterChange();
              this.selectedSpecificHazardIds = res.nuggetHazardTypes.flatMap(item =>
                item.nuggetHazardClusters.flatMap(cluster =>
                  cluster.nuggetHazardValues.map(value => value.hazardValueId)
                )
              );

              // Edit: Fill IHR Technical Area.
              this.selectedIHRTechnicalAreaIds = res.nuggetIHRTechnicalAreas.map(techArea => techArea.ihrTechnicalAreaId);
              this.onIHRTechnicalAreaChange();
              this.selectedIHRLevelIds = res.nuggetIHRTechnicalAreas.flatMap(item =>
                item.nuggetIHRLevels.map(ihrLevel => ihrLevel.ihrLevelId)
              );
              this.onIHRLevelChange();
              this.selectedSpecificIHRLevelIds = res.nuggetIHRTechnicalAreas.flatMap(item =>
                item.nuggetIHRLevels.flatMap(ihrLevel =>
                  ihrLevel.nuggetIHRSpecificLevels.map(value => value.ihrSpecificLevelId)
                )
              );

              // Edit: Fill IHR Capacities.
              this.selectedIHRCapacityIds = res.nuggetIHRCapacities.map(cap => cap.ihrCapacityId);
              this.onIHRCapacityChange();
              this.selectedSpecificIHRCapacityIds = res.nuggetIHRCapacities.flatMap(item =>
                item.nuggetIHRSpecificCapacities.map(ihrSpecLevel => ihrSpecLevel.ihrSpecificCapacityId)
              );

              // Edit: Fill Continent, Region and Countries.
              this.manageNugget.continentId = res.continentId;
              this.selectedRegionIds = res.nuggetRegions.map(reg => reg.regionId);
              this.onRegionChange();
              this.selectedCountryIds = res.nuggetRegions.flatMap(region =>
                region.nuggetCountries.map(country => country.countryId)
              );

              // Edit: Fill start date, end date and end date status.
              if (res.startDate) {
                this.startDate = new Date(res.startDate);
              }

              if (res.endDate) {
                this.endDate = new Date(res.endDate);
              }
              this.manageNugget.startDate = res.startDate;
              this.manageNugget.endDate = res.endDate;
              this.manageNugget.endDateStatus = res.endDateStatus;

              // Edit: Fill NOK Source, Population Type, Authors.
              this.selectedNokSourceIds = res.nuggetSourceTypes.map(sou => sou.nokSourceTypeId);
              this.selectedPersonIds = res.nuggetPersonTypes.map(per => per.personTypeId);
              res.nuggetAuthors.forEach(author => {
                if (author.date) {
                  author.date = new Date(author.date);
                }
                this.manageNugget.nuggetAuthors.push(author);
              });
              this.manageNugget.referenceLink = res.referenceLink;
              this.manageNugget.reason = res.reason;

              // Edit: Fill Format Type.
              this.manageNugget.formatType = res.formatType;
              this.manageNugget.textType = res.textType;

              // Edit: Fill Description Lines.
              if (res.nuggetDescriptionLines != null) {
                let firstOrderedLine = res.nuggetDescriptionLines.find(line => line.parentLineId === null);
                this.parentIsOrdered = firstOrderedLine ? firstOrderedLine.isOrdered : false;
                res.nuggetDescriptionLines
                  .filter(line => line.parentLineId === null)
                  .forEach(line => {
                    if (line.childLines.length > 0) {
                      line.isOrdered = line.childLines[0].isOrdered;
                    }
                    else {
                      line.isOrdered = false;
                    }
                  });
                this.formDescriptionLines = res.nuggetDescriptionLines;
              }



            },
            error: (err: any) => {
              this.loaderService.hide();
            }
          })

        }
      },
      error: (err: any) => {
        this.loaderService.hide();
      }
    })
  }

  sortCountriesAlphabetically(countries: Country[]): Country[] {
    return countries.sort((a, b) => a.name.localeCompare(b.name));
  }

  isSpecificHazardSelected(specificHazardId: number): boolean {
    return this.selectedSpecificHazardIds.includes(specificHazardId);
  }
  isSpecificIhrLevelSelected(specificIhrLevelId: number): boolean {
    return this.selectedSpecificIHRLevelIds.includes(specificIhrLevelId);
  }
  isSpecificIhrCapacitySelected(specificIhrCapacityIdId: number): boolean {
    return this.selectedSpecificIHRCapacityIds.includes(specificIhrCapacityIdId);
  }

  openStep(stepNumber: number) {
    this.currentStep = stepNumber;
  }
  validateStep(form: NgForm, stepNumber: number, openStep: boolean) {
    let isValid = true;
    Object.keys(form.controls).forEach(field => {
      let cont = form.form.get(field);
      if (cont) {
        cont.markAsTouched({ onlySelf: true });
      }
    });

    if (form.invalid) {
      isValid = false;
    }

    // Additional Stepwise checks.
    if (isValid && this.isSubmit) {
      switch (stepNumber) {
        case 1:
          {
            isValid = this.isEndDateValid() && this.manageNugget.title.length > 0 && this.manageNugget.shortDescription.length > 0 && (!!this.endDate || (this.manageNugget.endDateStatus && this.manageNugget.endDateStatus > 0));
            break;
          }

        case 2:
          {
            if (this.manageNugget.formatType === 0) {
              isValid = false;
            } else if (this.manageNugget.formatType == 1) {
              if (this.manageNugget.textType == 1 && this.manageNugget.description == '') {
                isValid = false;
              } else if (this.manageNugget.textType == 2 && (!this.formDescriptionLines || this.formDescriptionLines.length === 0 || !this.formDescriptionLines[0].description)) {
                isValid = false;
              }
            } else if (this.manageNugget.formatType == 2 || this.manageNugget.formatType == 3) {
              if (this.manageNugget.tempMediaPath === '') {
                isValid = false;
              }
            }
            break;
          }

        case 3:
          {
            isValid = this.atleastOneSpecificHazard() && this.atleastOneSpecificIHRCapacity() && this.atleastOneSpecificIHRLevel();
            break;

          }
        case 4:
          {
            isValid = this.manageNugget.nuggetAuthors.length > 0;
            break;

          }
      }
    }
    if (isValid && openStep) {
      let nextStep = stepNumber + 1;
      this.openStep(nextStep);
    }

    return isValid;
  }
  isStepActive(tabNumber: Number) {
    return tabNumber === this.currentStep;
  }

  // Hazard Events.
  onHazardTypeChange() {
    this.filterHazardClusters();
    this.filterHazards();
  }
  onHazardClusterChange() {
    this.filterHazards();
  }

  filterHazardClusters() {
    this.filteredHazardClusters = _.orderBy(_.filter(this.allHazardClusters, cluster => this.selectedHazardTypeIds.includes(cluster.hazardTypeId)), (ord) => {
      return ord.hazardTypeId;
    });

    // Update the Cluster Model. Remove items which do not exist in the list.
    this.updateClusters();
  }

  filterHazards() {
    this.filteredSpecificHazard = _.orderBy(_.filter(this.allSpecificHazard, cluster => this.selectedHazardClusterIds.includes(cluster.hazardClusterId)), (ord) => {
      return ord.name;
    });

    // Update the Cluster Model. Remove items which do not exist in the list.
    this.updateClusters();
    // Update the Specific Hazard Model. Remove items which do not exist in the list.
    this.updateHazardValues();
  }

  toggleHazardSelection(hazard: HazardValue) {
    let index = this.selectedSpecificHazardIds.indexOf(hazard.hazardValueId);
    if (index === -1) {
      this.selectedSpecificHazardIds.push(hazard.hazardValueId);
    } else {
      this.selectedSpecificHazardIds.splice(index, 1);
    }
  }
  atleastOneSpecificHazard() {
    return this.selectedSpecificHazardIds.length > 0;
  }
  atleastOneSpecificIHRLevel() {
    return this.selectedSpecificIHRLevelIds.length > 0;
  }
  atleastOneSpecificIHRCapacity() {
    return this.selectedSpecificIHRCapacityIds.length > 0;
  }


  updateClusters() {
    let currentHazardClusterIds = this.filteredHazardClusters.map(cl => cl.hazardClusterId);
    let matchingClusterIds = _.intersection(this.selectedHazardClusterIds, currentHazardClusterIds);
    this.selectedHazardClusterIds = _.reject(this.selectedHazardClusterIds, id => !matchingClusterIds.includes(id));
  }
  updateHazardValues() {
    let currentHazardIds = this.filteredSpecificHazard.map(cl => cl.hazardValueId);
    let matchingValueIds = _.intersection(this.selectedSpecificHazardIds, currentHazardIds);
    this.selectedSpecificHazardIds = _.reject(this.selectedSpecificHazardIds, id => !matchingValueIds.includes(id));
  }


  // IHR Technical Area Events.
  onIHRTechnicalAreaChange() {
    this.filterIHRLevels();
    this.filterIHRSpecificLevels();
  }
  onIHRLevelChange() {
    this.filterIHRSpecificLevels();
  }

  filterIHRLevels() {
    this.filteredIHRLevels = _.orderBy(_.filter(this.allIHRLevels, ihrLevel => this.selectedIHRTechnicalAreaIds.includes(ihrLevel.ihrTechnicalAreaId)), (ord) => {
      return ord.ihrTechnicalAreaId;
    });

    // Update the IHRLevel Model. Remove items which do not exist in the list.
    this.updateIhrLevels();
  }

  filterIHRSpecificLevels() {
    this.filteredSpecificIHRLevels = _.orderBy(_.filter(this.allSpecificIHRLevels, ihrSpecLevel => this.selectedIHRLevelIds.includes(ihrSpecLevel.ihrLevelId)), (ord) => {
      return ord.ihrLevelId;
    });

    // Update the IHRLevel Model. Remove items which do not exist in the list.
    this.updateIhrLevels();
    // Update the Specific IHR Level Model. Remove items which do not exist in the list.
    this.updateIhrSpecificLevels();
  }

  toggleIHRSpecificLevelSelection(ihrSpecificLevel: IhrSpecificLevel) {
    let index = this.selectedSpecificIHRLevelIds.indexOf(ihrSpecificLevel.ihrSpecificLevelId);
    if (index === -1) {

      this.selectedSpecificIHRLevelIds.push(ihrSpecificLevel.ihrSpecificLevelId);
    } else {
      this.selectedSpecificIHRLevelIds.splice(index, 1);
    }
  }


  updateIhrLevels() {
    let currentIhrLevelIds = this.filteredIHRLevels.map(cl => cl.ihrLevelId);
    let matchingIhrlevelIds = _.intersection(this.selectedIHRLevelIds, currentIhrLevelIds);
    this.selectedIHRLevelIds = _.reject(this.selectedIHRLevelIds, id => !matchingIhrlevelIds.includes(id));
  }
  updateIhrSpecificLevels() {

    let currentSpecificLevelIds = this.filteredSpecificIHRLevels.map(cl => cl.ihrSpecificLevelId);
    let matchingSpecificLevelIds = _.intersection(this.selectedSpecificIHRLevelIds, currentSpecificLevelIds);
    this.selectedSpecificIHRLevelIds = _.reject(this.selectedSpecificIHRLevelIds, id => !matchingSpecificLevelIds.includes(id));

  }


  // IHR Capacity Events.
  onIHRCapacityChange() {
    this.filterIhrSpecificCapacities();
  }


  filterIhrSpecificCapacities() {
    this.filteredIhrSpecificCapacities = _.orderBy(_.filter(this.allIhrSpecificCapacities, specCapacity => this.selectedIHRCapacityIds.includes(specCapacity.ihrCapacityId)), (ord) => {
      return ord.ihrCapacityId;
    });

    // Update the Specific IHR Capacity Model. Remove items which do not exist in the list.
    this.updateSpecificIHRCapacity();
  }

  toggleIHRSpecificCapacitySelection(ihrSpecificCapacity: IhrSpecificCapacity) {
    let index = this.selectedSpecificIHRCapacityIds
      .indexOf(ihrSpecificCapacity.ihrSpecificCapacityId);
    if (index === -1) {
      this.selectedSpecificIHRCapacityIds.push(ihrSpecificCapacity.ihrSpecificCapacityId);
    } else {
      this.selectedSpecificIHRCapacityIds.splice(index, 1);
    }
  }



  updateSpecificIHRCapacity() {
    let currentSpecificIhrCapacityIds = this.filteredIhrSpecificCapacities.map(cl => cl.ihrSpecificCapacityId);
    let matchingCapacityIds = _.intersection(this.selectedSpecificIHRCapacityIds, currentSpecificIhrCapacityIds);
    this.selectedSpecificIHRCapacityIds = _.reject(this.selectedSpecificIHRCapacityIds, id => !matchingCapacityIds.includes(id));
  }


  // Continent, Region, COuntry
  // onContinentChange() {
  //   this.filteredRegions = [];
  //   this.filteredCountries = [];
  //   let placeHolderRegion: Region = { regionId: 0, name: "-- SELECT REGION --", countries: [], continentId: 0 };
  //   this.filteredRegions = _.orderBy(_.filter(this.allRegions, reg => this.manageNugget.continentId == reg.continentId), (ord) => {
  //     return ord.name;
  //   });
  //   if (this.filteredRegions.length > 0) {
  //     this.filteredRegions.splice(0, 0, placeHolderRegion);
  //   }
  // }
  onRegionChange() {
    this.filteredCountries = [];

    this.filteredCountries = _.orderBy(_.filter(this.allCountries, count => this.selectedRegionIds.includes(count.regionId)), (ord) => {
      return ord.name;
    });
  }
  
  // Nugget Description
  addDescriptionLine() {
    if (this.formDescriptionLines == null) {
      this.formDescriptionLines = [];
    }
    this.formDescriptionLines.push({ isOrdered: true, nuggetDescriptionLineId: this.generateTimestampId("descline"), description: "", isNew: true, childLines: [] });
  }
  removeDescriptionLine(descriptionLine: NuggetDescriptionLine) {
    this.formDescriptionLines = _.reject(this.formDescriptionLines, (line) => {
      return line.nuggetDescriptionLineId == descriptionLine.nuggetDescriptionLineId;
    });
  }

  addChildLine(descriptionLine: NuggetDescriptionLine) {
    if (descriptionLine.childLines == null) {
      descriptionLine.childLines = [];
    }
    descriptionLine.childLines.push({ isOrdered: true, nuggetDescriptionLineId: this.generateTimestampId("childline"), description: "", isNew: true, childLines: [] });

  }
  removeChildLine(descriptionLine: NuggetDescriptionLine, childline: NuggetDescriptionLine) {
    descriptionLine.childLines = _.reject(descriptionLine.childLines, (line) => {
      return line.nuggetDescriptionLineId == childline.nuggetDescriptionLineId;
    });

  }

  // Nugget Authors.
  addAuthor() {
    if (this.manageNugget.nuggetAuthors == null) {
      this.manageNugget.nuggetAuthors = [];
    }
    this.manageNugget.nuggetAuthors.push({ isNew: true, name: "", countryId: 0, title: "", nuggetAuthorId: this.generateTimestampId("author") });
  }
  removeAuthor(nuggetAuthor: NuggetAuthor) {
    this.manageNugget.nuggetAuthors = _.reject(this.manageNugget.nuggetAuthors, (auth) => {
      return auth.nuggetAuthorId == nuggetAuthor.nuggetAuthorId;
    });
  }
  atleastOneAuthor() {
    return this.manageNugget.nuggetAuthors.length > 0;
  }

  isEndDateValid() {
    if (!this.endDate || !this.startDate) {
      return true;
    }
    return new Date(this.endDate) > new Date(this.startDate);
  }



  onThumbnailChange(event: any) {
    const files = event.target.files as FileList;

    if (files.length > 0) {
      this.manageNugget.thumbnail = URL.createObjectURL(files[0]);
      this.manageNugget.tempThumbnailPath = this.manageNugget.thumbnail;
      this.thumbnailFile = files[0];
      this.imageChangedEvent = event;
    }
  }

  openImageModal(templateRef: TemplateRef<any>): void {
    if(!this.manageNugget.thumbnail){
      return;
    }
    this.uploadModalRef = this.modalService.open(templateRef, {
      backdrop: 'static',
      size: 'xl',
    });
  }

  closeImageModal() {
    this.uploadModalRef.close();
  }

  continueImageModal() {
    if (this.croppedImageBase64) {
      this.manageNugget.tempThumbnailPath = this.croppedImage;
      this.thumbnailFile = new File([base64ToFile(this.croppedImageBase64)], 'thumbnail.png');
      this.uploadModalRef.close();
    }
  }
  
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage =  this.sanitizer.bypassSecurityTrustUrl(event.base64!) as string;    
    this.croppedImageBase64 = event.base64 ;
  }


  onMediaChange(event: any) {
    const files = event.target.files as FileList;

    if (files.length > 0) {
      this.manageNugget.media = URL.createObjectURL(files[0]);
      this.manageNugget.tempMediaPath = this.manageNugget.media;
      this.mediaFile = files[0];
      // this.resetInput();
    }
  }

  submitNok(step1Form: NgForm, step2Form: NgForm, step3Form: NgForm, step4Form: NgForm) {
    this.isSubmit = false;
    setTimeout(() => {
      if (this.isDraftValid(step1Form)) {
        this.saveNOK(step1Form, step2Form, step3Form, step4Form, (nuggetId: number) => {
          this.isSubmit = true;
          setTimeout(() => {
            let isStep1Valid = this.validateStep(step1Form, 1, false);
            let isStep2Valid = this.validateStep(step2Form, 2, false);
            let isStep3Valid = this.validateStep(step3Form, 3, false);
            let isStep4Valid = this.validateStep(step4Form, 4, false);
            if (isStep1Valid && isStep2Valid && isStep3Valid && isStep4Valid) {

              let updateStatusComamnd: UpdateStatusCommand =
              {
                nuggetId: nuggetId,
                status: this.manageNugget.status != NuggetStatus.Return ? NuggetStatus.Submit : NuggetStatus.Checkin,
                reason: ''
              };
              this.loaderService.show();
              this.nokService.updateStatus(updateStatusComamnd).subscribe({
                next: (res: boolean) => {
                  this.loaderService.hide();
                  this.toastr.success("NoK submitted successfully");
                  this.redirectToNuggetList();
                },
                error: (err: any) => {
                  this.loaderService.hide();
                }
              });

            }
            else {
              this.toastr.warning("NoK can not be sumitted as some of the fields are not valid. Please see error messages.");
              if (!isStep1Valid) {
                this.openStep(1);
              }
              else if (!isStep2Valid) {
                this.openStep(2);
              }
              else if (!isStep3Valid) {
                this.openStep(3);
              }
              else if (!isStep4Valid) {
                this.openStep(4);
              }
            }
          }, 100);
        });
      }
      else {
        this.toastr.warning("Some of the fields are not valid. Please see the error messages.");
        this.openStep(1);
      }
    }, 100);

  }
  saveDraft(step1Form: NgForm, step2Form: NgForm, step3Form: NgForm, step4Form: NgForm) {
    this.isSubmit = false;
    setTimeout(() => {
      if (this.isDraftValid(step1Form)) {
        this.saveNOK(step1Form, step2Form, step3Form, step4Form);
      }
      else {
        this.toastr.warning("Some of the fields are not valid. Please see the error messages.");
        this.openStep(1);
      }
    }, 100);
  }
  redirectToNuggetList() {
    this.router.navigate(['/nok/list']);
  }
  isUpdateDisabled() {
    return (this.loggedInUser?.role?.roleId !== 1 && 
      [2, 5, 6, 7].includes(this.manageNugget.status)) ||
     (this.manageNugget.status === 4 && this.loggedInUser?.role?.roleId === 2);
  }

  isSaveButtonEnabled()
  {
    const roleId = this.loggedInUser?.role?.roleId;
    const status = this.manageNugget.status;

    if (roleId === 1) {
      return true;
    }

    if (roleId === 2) {
      return [1, 3].includes(status);
    }

    if (roleId === 3) {
      return [1, 3, 4].includes(status);
    }

    return false;
  }
  
  isPublishableNugget() {
    const roleId = this.loggedInUser?.role?.roleId;
    return roleId !== undefined && [1, 2].includes(roleId) && this.manageNugget.status === 3;
  }

  gotoPreview() {
    this.router.navigate(['/nok/preview', this.manageNugget.nuggetId]);
  }

  checkInNugget() {
    if (confirm("Nugget status will be chaged to check-in, Do you want to proceed?")) {
      this.loaderService.show();
      let updateStatusComamnd: UpdateStatusCommand =
      {
        nuggetId: this.queryNuggetId,
        status: NuggetStatus.Checkin,
        reason: ''
      };
      this.nokService.updateStatus(updateStatusComamnd).subscribe({
        next: (res: boolean) => {
          this.loaderService.hide();
          this.toastr.success("NoK status is changed to check-in.");
          this.redirectToNuggetList();
        },
        error: (err: any) => {
          this.loaderService.hide();
        }
      });
    }
  }

  publishNugget() {
    if (confirm("Are you sure want to publish the NoK?")) {
      this.loaderService.show();

      let updateStatusComamnd: UpdateStatusCommand =
      {
        nuggetId: this.manageNugget.nuggetId,
        status: NuggetStatus.Published,
        reason: ''
      };
      this.nokService.updateStatus(updateStatusComamnd).subscribe({
        next: (res: boolean) => {
          this.loaderService.hide();
          this.toastr.success("NoK published successfully.");
          this.redirectToNuggetList();
        },
        error: (err: any) => {
          this.loaderService.hide();
        }
      });
    }
  }

  closeModal() {
    this.modal.show = false;
  }
  openReturnModal() {
    this.modal.nuggetId = this.manageNugget.nuggetId;
    this.modal.show = true;
    this.modal.buttonTitle = "Return";
    this.modal.message = "Reason for return";
    this.modal.modalOperation = NuggetStatus.Return;
    this.modal.title = "Return NoK";
  }

  openRejectModal() {
    this.modal.nuggetId = this.manageNugget.nuggetId;
    this.modal.show = true;
    this.modal.buttonTitle = "Reject";
    this.modal.message = "Reason for rejection";
    this.modal.modalOperation = NuggetStatus.Reject;
    this.modal.title = "Reject NoK";
  }

  returnRejectNugget() {
    if (this.manageNugget.nuggetId > 0) {
      let updateStatusComamnd: UpdateStatusCommand =
      {
        nuggetId: this.manageNugget.nuggetId,
        status: this.modal.modalOperation,
        reason: this.modal.reason
      };
      this.modal.disableButtons = true;
      this.nokService.updateStatus(updateStatusComamnd).subscribe({
        next: (res: boolean) => {
          this.modal.disableButtons = false;
          this.modal.show = false;
          let successMsg = this.modal.modalOperation === NuggetStatus.Reject ? "NoK has been rejected." : "NoK has been returned for the modification.";
          this.toastr.success(successMsg);
          this.redirectToNuggetList();
        },
        error: (err: any) => {
          this.modal.disableButtons = false;

          this.modal.show = false;
        }
      });
    }

  }

  // Select all functionality
  selectAllRegions(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.checked) {
      this.selectedRegionIds = this.filteredRegions.map(region => region.regionId);
    } else {
      this.selectedRegionIds = [];
    }
    this.onRegionChange();
  }

  areAllRegionsSelected(): boolean {
    return this.selectedRegionIds.length === this.filteredRegions.length;
  }

 

  selectAllCountries(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.checked) {
      this.selectedCountryIds = this.filteredCountries.map(country => country.countryId);
    } else {
      this.selectedCountryIds = [];
    }
  }

  areAllCountriesSelected(): boolean {
    return this.selectedCountryIds.length === this.filteredCountries.length;
  }

  selectAllPopulationType(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.checked) {
      this.selectedPersonIds = this.allPersonTypes.map(personType => personType.personTypeId);
    } else {
      this.selectedPersonIds = [];
    }
  }

  areAllPopulationTypeSelected(): boolean {
    return this.selectedPersonIds.length === this.allPersonTypes.length;
  }

  selectAllNokSource(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.checked) {
      this.selectedNokSourceIds = this.allNokSourceTypes.map(source => source.nokSourceTypeId);
    } else {
      this.selectedNokSourceIds = [];
    }
  }

  areAllNokSourceSelected(): boolean {
    return this.selectedNokSourceIds.length === this.allNokSourceTypes.length;
  }

  selectAllHazardTypes(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.checked) {
      this.selectedHazardTypeIds = this.allHazardTypes.map(hazardType => hazardType.hazardTypeId);
    } else {
      this.selectedHazardTypeIds = [];
    }

    this.onHazardTypeChange();
  }

  areAllHazardTypeSelected(): boolean {
    return this.selectedHazardTypeIds.length === this.allHazardTypes.length;
  }

  selectAllHazardClusters(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.checked) {
      this.selectedHazardClusterIds = this.filteredHazardClusters.map(cluster => cluster.hazardClusterId);
    } else {
      this.selectedHazardClusterIds = [];
    }
    this.onHazardClusterChange();
  }

  areAllHazardClustersSelected(): boolean {
    return this.selectedHazardClusterIds.length === this.allHazardClusters.length;
  }

  selectAllHazards(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.checked) {
      this.selectedSpecificHazardIds = this.filteredSpecificHazard.map(hazard => hazard.hazardValueId);
    } else {
      this.selectedSpecificHazardIds = [];
    }
  }

  areAllHazardsSelected(): boolean {
    return this.selectedSpecificHazardIds.length === this.filteredSpecificHazard.length;
  }

  selectAllPillars(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.checked) {
      this.selectedIHRTechnicalAreaIds = this.allIHRTechnicalArea.map(techArea => techArea.ihrTechnicalAreaId);
    } else {
      this.selectedIHRTechnicalAreaIds = [];
    }

    this.onIHRTechnicalAreaChange();
  }

  areAllPillarsSelected(): boolean {
    return this.selectedIHRTechnicalAreaIds.length === this.allIHRTechnicalArea.length;
  }

  selectAllTechAreas(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.checked) {
      this.selectedIHRLevelIds = this.filteredIHRLevels.map(ihrLevel => ihrLevel.ihrLevelId);
    } else {
      this.selectedIHRLevelIds = [];
    }

    this.onIHRLevelChange();
  }

  areAllTechAreasSelected(): boolean {
    return this.selectedIHRLevelIds.length === this.filteredIHRLevels.length;
  }
  
  selectAllIndicators(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.checked) {
      this.selectedSpecificIHRLevelIds = this.filteredSpecificIHRLevels.map(speclevel => speclevel.ihrSpecificLevelId);
    } else {
      this.selectedSpecificIHRLevelIds = [];
    }

  }

  areAllIndicatorsSelected(): boolean {
    return this.selectedSpecificIHRLevelIds.length === this.filteredSpecificIHRLevels.length;
  }

  selectAllBenchmarks(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.checked) {
      this.selectedIHRCapacityIds = this.allIhrCapacities.map(ihrcapacity => ihrcapacity.ihrCapacityId);
    } else {
      this.selectedIHRCapacityIds = [];
    }

    this.onIHRCapacityChange();

  }

  areAllBenchmarksSelected(): boolean {
    return this.selectedIHRCapacityIds.length === this.allIhrCapacities.length;
  }

  selectAllIhrcapacities(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.checked) {
      this.selectedSpecificIHRCapacityIds = this.filteredIhrSpecificCapacities.map(specCapacity => specCapacity.ihrSpecificCapacityId);
    } else {
      this.selectedSpecificIHRCapacityIds = [];
    }
  }

  areAllIhrcapacitiesSelected(): boolean {
    return this.selectedSpecificIHRCapacityIds.length === this.filteredIhrSpecificCapacities.length;
  }
  
  removeThumbnail() {
    if (confirm("Are you sure you want to remove the thumbnail?")) {
      // Clear the thumbnail and temporary path on the client side
      this.manageNugget.thumbnail = '';
      this.manageNugget.tempThumbnailPath = '';
      this.thumbnailFile = undefined; // Clear the file as well
      
      // Check if the nugget ID is valid, indicating that we need to remove it from the server
      if (this.manageNugget.nuggetId > 0) {
        this.loaderService.show();
  
        this.nokService.removeNuggetThumbnail(this.manageNugget.nuggetId).subscribe({
          next: (res) => {
            this.loaderService.hide();
            this.toastr.success("Thumbnail removed successfully");
          },
          error: (err: any) => {
            this.loaderService.hide();
            this.toastr.success("Error while removing the thumbnail");
          }
        });
      }
      else
      {
        this.toastr.success("Thumbnail removed successfully");
      }
    }
  }
  

  private isDraftValid(step1Form: NgForm) {
    return this.validateStep(step1Form, 1, false);
  }

  private saveNOK(step1Form: NgForm, step2Form: NgForm, step3Form: NgForm, step4Form: NgForm, submitCallback?: (nuggetId: number) => void) {

    this.manageNugget.nuggetHazardTypes = [];
    this.manageNugget.nuggetIHRTechnicalAreas = [];
    this.manageNugget.nuggetIHRCapacities = [];
    this.manageNugget.nuggetTags = [];
    this.manageNugget.nuggetKeywords = [];
    this.manageNugget.nuggetRegions = [];
    this.manageNugget.nuggetSourceTypes = [];
    this.manageNugget.nuggetPersonTypes = [];


    // Setup the Hazard Model.
    this.selectedHazardTypeIds.forEach(hazardTypeId => {
      let findHazardType = this.allHazardTypes.find(haz => haz.hazardTypeId === hazardTypeId);
      if (findHazardType) {
        let hazardType: NuggetHazardType = {
          name: findHazardType.name,
          hazardTypeId: hazardTypeId,
          nuggetHazardClusters: this.allHazardClusters
            .filter(cluster => cluster.hazardTypeId === hazardTypeId && this.selectedHazardClusterIds.includes(cluster.hazardClusterId))
            .map(cluster => {
              let nuggetHazardValues: NuggetHazardValue[] = this.allSpecificHazard
                .filter(hazard => hazard.hazardClusterId === cluster.hazardClusterId && this.selectedSpecificHazardIds.includes(hazard.hazardValueId))
                .map(hazard => ({
                  name: hazard.name,
                  hazardValueId: hazard.hazardValueId
                }));
              return {
                hazardClusterId: cluster.hazardClusterId,
                name: cluster.name,
                nuggetHazardValues: nuggetHazardValues
              };
            })
        };
        this.manageNugget.nuggetHazardTypes.push(hazardType);
      }
    });

    // Setup the IHR Tech Area Model.
    this.selectedIHRTechnicalAreaIds.forEach(techAreadId => {
      let findTechArea = this.allIHRTechnicalArea.find(techArea => techArea.ihrTechnicalAreaId === techAreadId);
      if (findTechArea) {
        let techArea: NuggetIHRTechnicalArea = {
          name: findTechArea.name,
          ihrTechnicalAreaId: techAreadId,
          nuggetIHRLevels: this.allIHRLevels
            .filter(ihrLevel => ihrLevel.ihrTechnicalAreaId === techAreadId && this.selectedIHRLevelIds.includes(ihrLevel.ihrLevelId))
            .map(ihrLevel => {
              let nuggetspecificIhrLevels: NuggetIHRSpecificLevel[] = this.allSpecificIHRLevels
                .filter(specLevel => ihrLevel.ihrLevelId === specLevel.ihrLevelId && this.selectedSpecificIHRLevelIds.includes(specLevel.ihrSpecificLevelId))
                .map(specLevel => ({
                  name: specLevel.name,
                  ihrSpecificLevelId: specLevel.ihrSpecificLevelId
                }));
              return {
                ihrLevelId: ihrLevel.ihrLevelId,
                name: ihrLevel.name,
                nuggetIHRSpecificLevels: nuggetspecificIhrLevels
              };
            })
        };
        this.manageNugget.nuggetIHRTechnicalAreas.push(techArea);
      }
    });

    // Setup the IHR Capacity Model.

    // Setup the IHR Tech Area Model.
    this.selectedIHRCapacityIds.forEach(ihrCapacityId => {
      let findIhrCapacity = this.allIhrCapacities.find(ihrCapacity => ihrCapacity.ihrCapacityId === ihrCapacityId);
      if (findIhrCapacity) {
        let nuggetIHRCapacity: NuggetIHRCapacity = {
          name: findIhrCapacity.name,
          ihrCapacityId: ihrCapacityId,
          nuggetIHRSpecificCapacities: this.allIhrSpecificCapacities
            .filter(specCapacity => specCapacity.ihrCapacityId === ihrCapacityId && this.selectedSpecificIHRCapacityIds.includes(specCapacity.ihrSpecificCapacityId))
            .map(specCapacity => {
              return {
                ihrSpecificCapacityId: specCapacity.ihrSpecificCapacityId,
                name: specCapacity.name
              };
            })
        };
        this.manageNugget.nuggetIHRCapacities.push(nuggetIHRCapacity);
      }
    });

    // Setup tags and keywords

    this.manageNugget.nuggetTags = this.manageNugget.nuggetTagsModel.map(tag => typeof tag === 'string' ? { title: tag } : { title: tag.item });
    this.manageNugget.nuggetKeywords = this.manageNugget.nuggetKeywordsModel.map(keyword => typeof keyword === 'string' ? { title: keyword } : { title: keyword.item });


    // Setup start and end date.
    if (this.startDate != null) {
      this.manageNugget.startDate = this.startDate.toLocaleDateString('en-US');
    }
    if (this.endDate != null) {
      this.manageNugget.endDate = this.endDate.toLocaleDateString('en-US');
    }


    this.manageNugget.nuggetRegions = this.selectedRegionIds.map(rId => {
      let region: NuggetRegion = { regionId: rId, nuggetCountries: [] };
      region.nuggetCountries = this.allCountries.filter(x => x.regionId === rId && this.selectedCountryIds.includes(x.countryId));
      return region;
    });

    this.manageNugget.nuggetSourceTypes = this.selectedNokSourceIds.map(id => ({
      nokSourceTypeId: id
    }));

    this.manageNugget.nuggetPersonTypes = this.selectedPersonIds.map(id => ({
      personTypeId: id
    }));

    // Setup Description Lines
    if (this.formDescriptionLines) {
      this.manageNugget.nuggetDescriptionLines = _.cloneDeep(this.formDescriptionLines);
      this.manageNugget.nuggetDescriptionLines.forEach(parentLine => {
        if (parentLine.isNew) {
          parentLine.nuggetDescriptionLineId = '0';
        }
        // Setup Child line order
        if (parentLine.childLines.length > 0) {
          parentLine.childLines.forEach(cLine => {
            if (cLine.isNew) {
              cLine.nuggetDescriptionLineId = '0';
            }
            cLine.isOrdered = parentLine.isOrdered;
          });
        }
        parentLine.isOrdered = this.parentIsOrdered;
      });
    }

    // Setup Authors
    if (this.manageNugget.nuggetAuthors) {
      this.manageNugget.nuggetAuthors.forEach(author => {
        if (author.isNew) {
          author.nuggetAuthorId = '0';
        }
      });
    }

    this.loaderService.show();
    this.nokService.manageNugget(this.manageNugget).subscribe({
      next: (res: ManageNuggetCommand) => {
        this.loaderService.hide();

        this.manageNugget.nuggetId = res.nuggetId;
        let successMsg = "NoK data saved successfully.";
        if (this.thumbnailFile !== undefined || this.mediaFile !== undefined) {
          this.UploadFiles(res.nuggetId, () => {
            this.toastr.success(successMsg);
            if (typeof (submitCallback) == 'function') {
              submitCallback(res.nuggetId);
            }
            else {
              this.toastr.success(successMsg);
            }
          });
        }
        else {
          if (typeof (submitCallback) == 'function') {
            submitCallback(res.nuggetId);
          }
          else {
            this.toastr.success(successMsg);
          }
        }
      },
      error: (err: any) => {
        this.loaderService.hide();
      }
    });
  }

  private UploadFiles(nuggetId: number, callback: any) {
    const formData = new FormData();
    formData.append("nuggetId", nuggetId.toString());
    if (this.thumbnailFile !== undefined) {
      formData.append("Thumbnail", this.thumbnailFile);

    }
    if (this.mediaFile !== undefined && this.manageNugget.formatType != 1) {
      formData.append("Media", this.mediaFile);
    }

    this.loaderService.show();
    this.nokService.uploadNuggetAssets(formData).subscribe({
      next: (res: GetCategoryResponse) => {
        this.loaderService.hide();
        if (callback) {
          callback();
        }
      },
      error: (err: any) => {
        this.loaderService.hide();
      }
    });
  }

  private getSuggestions(type: number): Observable<any[]> {
    const search$ = type === 1 ? this.keyWordsearch$ : this.tagSearch$;

    return search$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(term =>
        this.http.post<AutoSuggestResponse>(`${environment.serverUrl}/Nugget/autosuggest`, {
          searchKey: term,
          type: type
        }).pipe(
          tap(),
          catchError(() => {
            return of({ Message: '', Result: [], StatusCode: 0, Version: '' });
          }),
          // Extracting 'Result' array from the API response
          map((response: AutoSuggestResponse) => response.Result || [])
        )
      )
    );
  }

  private initializeManageNugget(): ManageNuggetCommand {
    return {
      nuggetId: 0,
      title: "",
      subTitle: "",
      shortDescription: "",
      description: "",
      continentId: 1,
      countryId: 0,
      formatType: 0,
      textType: 1,
      status: 1,
      thumbnail: "",
      tempThumbnailPath: "",
      media: "",
      transcript:"",
      tempMediaPath:"",
      startDate: null,
      endDate: null,
      endDateStatus: 0,
      referenceLink: "",
      reason: null,
      nuggetTags: [],
      nuggetTagsModel: [],
      nuggetKeywords: [],
      nuggetKeywordsModel: [],
      nuggetDescriptionLines: [],
      nuggetHazardTypes: [],
      nuggetIHRTechnicalAreas: [],
      nuggetIHRCapacities: [],
      nuggetRegions: [],
      nuggetPersonTypes: [],
      nuggetSourceTypes: [],
      nuggetAuthors: []
    };
  }
  private generateTimestampId(prefix: string) {
    const timestamp = new Date().getTime(); // Get current timestamp
    const randomNum = Math.floor(Math.random() * 10000); // Generate a random number
    return `${prefix}-${timestamp}-${randomNum}`; // Combine timestamp and random number
  }

}
