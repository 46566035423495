import { Component, HostListener, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginService } from 'src/app/core/services/login/login.service';
import { RouterLink } from '@angular/router';
import { LoginInfo } from 'src/app/core/models/login/login-info.model';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignupComponent } from 'src/app/shared/components/signup/signup.component';
import { LoggedInUser } from 'src/app/core/models/user/loggedInUser.model';
import { UserService } from 'src/app/core/services/user/user.service';
import { SharedvariablesService } from 'src/app/core/services/sharedvariables/sharedvariables.service';


@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  private loginService = inject(LoginService);
  private utilService = inject(UtilsService);
  private modalService = inject(NgbModal);
  private userService = inject(UserService);
  IsUserLoggedIn: boolean = false;
  userActivity: NodeJS.Timeout;
  loggedInUser: LoggedInUser;
  profileImage: string | undefined = undefined;
  totalPublishedNok: number= 0;
  constructor(private sharedVariablesService: SharedvariablesService)
  {
    this.sharedVariablesService.totalPublishedNok$.subscribe(value => {
      this.totalPublishedNok = value;
    });
  }
  ngOnInit(): void {
    this.loginService.preRequire();
    this.loginService.getLoginInfo()
    .subscribe((rsp) => {            
      this.IsUserLoggedIn = !rsp || Object.keys(rsp).length === 0 ? false : true;
    });
    this.loginService.getLoginInfo().subscribe((data)=>{
      this.loggedInUser = data;
    })
    
    this.profileImage = this.loggedInUser.profilePicture ;
  }

  signUp(){
    const modalRef = this.modalService.open(SignupComponent,{ size: 'xl' , backdrop:'static' })
  }

  loginUser(): void {    
    this.loginService.login().subscribe({
      next: () => {
      },
      error: (error) => {
        console.error(error);
      }
    });    
  }

  logoutUser(): void { 
    this.loginService.logout();
  }

  /**
   * set timeout for auto logout
   */
  setTimeout(): void {
    const loginInfo: LoginInfo = this.utilService.getToken() as LoginInfo;
    
    this.userActivity = setTimeout(() => this.logoutUser(), 3600000);
  }
  redirectToHome() {
    window.location.href = "/home";
  }
  searchPublishedNoks()
  {
    localStorage.setItem('emptySearch', "true");
    this.redirectToHome();
  }

   /**
   * on mouse move event clear previous timeout and add new timeout for logout
   */
  //  @HostListener('window:mousemove') refreshUserState(): void {
  //   clearTimeout(this.userActivity);
  //   this.setTimeout();
  // }
}
