import { Component, Injectable, inject } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, catchError, firstValueFrom, from, lastValueFrom, map, switchMap, throwError } from 'rxjs';
import { LoginInfo } from 'src/app/core/models/login/login-info.model';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { AuthenticationResult, SilentRequest } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { MsalService } from '@azure/msal-angular';
import { LoginService } from 'src/app/core/services/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class HttpConfigInterceptor implements HttpInterceptor  {

  constructor(private loginService: LoginService) {
  }
  
  private utilService = inject(UtilsService);
  private router = inject(Router);
  private authService = inject(MsalService);
  requestUrl: string = '';

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requestUrl = request.url;
    
    return from(this.getToken()).pipe(
      switchMap((loginInfo: LoginInfo) => {
        
        if(loginInfo && loginInfo.idToken)
        {
          // for graph API use access token
          if(request.url.includes('https://graph.microsoft.com')) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${loginInfo.accessToken}`,
                'Content-Type': 'image/jpeg', // SET THE CONTENT TYPES AS IMAGE/JPEG
              },
            });
          } 
          else {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${loginInfo.idToken}`,
              },
            });
          }
        }
        return next.handle(request).pipe(
          map((response) => {
            if (response instanceof HttpResponse) {
              // this.loaderService.hideLoader();
            }
            return response;
          }),
          catchError((error) => {
            // this.loaderService.hideLoader();
            if (error && error.status === 401) {
              error.message = "Your session has expired. Please sign-in again.";
              this.loginService.logoutFromFrontChannel();
              setTimeout(() => {
                window.location.href = "/home";
              }, 1000);
            }
            return throwError(() => error);
          })
        ) as any as Observable<HttpEvent<any>>; 
      })
    )
  }

  async getToken(): Promise<LoginInfo> {

    const expireOn: string | null =
      localStorage.getItem('expireOn') || null;
    var currDate : Date = new Date();
    
    if (expireOn != null && currDate >= new Date(expireOn)) {
      try {
        await firstValueFrom(this.loginService.login(this.router.url));
      }
      catch (error) {}
    }

    return this.utilService.getToken() as LoginInfo;
  }

  async refreshToken(): Promise<void> {
    try {
      const accessTokenRequest: SilentRequest = {
        scopes: [...environment.authOptions.scopes],
        account: this.authService.instance.getAllAccounts()[0],
        forceRefresh : true
      };
      if(!accessTokenRequest.account)
      {
        if(
          this.requestUrl.includes('home')
        ){
          return ;
        }
      }
      //toPromise deprecated
      const accessTokenResponse: AuthenticationResult = await lastValueFrom(
        this.authService.acquireTokenSilent(accessTokenRequest)
      );
      this.authService.instance.setActiveAccount(accessTokenResponse.account);
      const loginInfo: LoginInfo = {
        accessToken: accessTokenResponse.accessToken,
        idToken: accessTokenResponse.idToken,
      };
      
      localStorage.setItem('login-info', JSON.stringify(loginInfo));
      localStorage.setItem(
        'expireOn',
        accessTokenResponse.expiresOn
          ? accessTokenResponse.expiresOn.toString()
          : new Date().toString()
      );
    } catch (error) {
      console.error(error);
    }
  }
}
